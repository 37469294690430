import { gql } from "@apollo/client";

export const GQL_PAGINATED_CUSTOMERS = gql`
   query clients($filter: String, $first: Int, $page: Int, $orderBy: OrderByClause) {
      clients(filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
         data {
            id
            company_name
            fiscal_code
            vat_number
            tax_account_count
            comune {
               id
               description
            }
            province {
               id
               description
            }
            contract_expiring_date
         }
         paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
         }
      }
   }
`;

export const GQL_ALL_CUSTOMERS = gql`
   query clientAll {
      clientsAll {
         id
         company_name
      }
   }
`;

export const GQL_ALL_CUSTOMERS_CLIENT = gql`
   query clientsAll {
      clientsAll @client {
         id
         company_name
      }
   }
`;

export const GQL_CUSTOMER = gql`
   query client($id: ID!, $asset_type_filter: [AssetType]) {
      client(id: $id) {
         id
         company_name
         code
         fiscal_code
         vat_number
         place
         address
         zip_code
         comune {
            id
            code
            description
         }
         province {
            id
            code
            description
         }
         phone
         phone2
         referent
         email
         pec
         notes
         commission {
            commission_type
            percentage
            fixed
         }
         contract_date
         contract_expiring_date
         tari_expiring_notifications
         tax_accounts {
            id
            code
            tax_provider {
               id
               company_name
               rid_contestuale
               rid_avvio_recupero
               rid_eccedenze_alimentare
            }
            address
            place
            zip_code
            comune {
               id
               description
            }
            province {
               id
               description
            }
            building_codice

            closed
            closing_date
            closing_note
         }
         hasLogo {
            id
            bucket_key
         }
      }
      customerAssets(customer_id: $id, asset_type_filter: $asset_type_filter) {
         id
         file_name
      }
   }
`;

export const GQL_CUSTOMER_TAX_ACCOUNTS = gql`
   query clientTaxAccounts($client_id: ID!) {
      clientTaxAccounts(client_id: $client_id) {
         id
         code
         comune {
            id
            code
            description
         }
         province {
            id
            description
         }
         tax_provider {
            id
            company_name
            rid_contestuale
            rid_avvio_recupero
            rid_eccedenze_alimentare
         }
         closed
         closing_date
         closing_note
      }
   }
`;

export const GQL_CUSTOMER_COMMISSION_TYPE = gql`
   query clientCommission($id: ID!) {
      clientCommission(id: $id) {
         commission_type
         commission_percentage
         commission_fixed
      }
   }
`;

export const GQL_CUSTOMERS_EXPORT = gql`
   query exportClients {
      exportClients {
         content
         filename
         filetype
      }
   }
`;

export const GQL_PAGINATED_CUSTOMERS_DOSSIERS = gql`
   query clientsWithDossier(
      $filter: String
      $first: Int
      $page: Int
      $orderBy: OrderByClause
   ) {
      clientsWithDossier(
         filter: $filter
         first: $first
         page: $page
         orderBy: $orderBy
      ) {
         data {
            id
            company_name
            fiscal_code
            vat_number
            tax_account_count
            comune {
               id
               description
            }
            province {
               id
               description
            }
            contract_expiring_date
            dossiers {
               id
               name
               created_at
               lastStatus {
                  status
                  description
                  created_at
               }
               hasCompleteAssets {
                  is_complete
               }
               revenued
               revenued_at
               timeline
               comparison
            }
         }
         paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
         }
      }
   }
`;

export const GQL_EXPORT_TAX_ACCOUNTS = gql`
   query exportTaxAccounts($client_id: ID!) {
      exportTaxAccounts(client_id: $client_id) {
         content
         filename
         filetype
      }
   }
`;

export const GQL_CUSTOMER_TAX_ACCOUNTS_COSTS = gql`
   query clientTaxAccountsCosts($client_id: ID!) {
      clientTaxAccountsCosts(client_id: $client_id) {
         comune {
            id
            description
         }
         tax_account {
            id
            code
         }
         categories {
            id
            description
         }
         costs {
            year
            fixed_cost
            fixed_cost_difference
            variable_cost
            variable_cost_difference
            fixed_cost_mean
            fixed_cost_mean_difference
            variable_cost_mean
            variable_cost_mean_difference
            categories {
               category {
                  id
                  description
               }
               fixed_cost
               fixed_cost_previous
               variable_cost
               variable_cost_previous
            }
         }
         has_increase_alert
      }
   }
`;

export const GQL_CUSTOMER_TAX_ACCOUNTS_BILLS_COMPARISON = gql`
   query clientsTaxAccountsTariComparison($year: Int!) {
      clientsTaxAccountsTariComparison(year: $year) {
         id
         code
         client {
            id
            company_name
         }
      }
   }
`;

export const GQL_CUSTOMER_TAX_ACCOUNTS_BILLS_COMPARISON_EXPORT = gql`
   query exportTaxAccountTariComparisons($year: Int!) {
      exportTaxAccountTariComparisons(year: $year) {
         content
         filename
         filetype
      }
   }
`;
