import { Row, Col } from "antd";
import React from "react";

const Header = () => {
   return (
      <Row>
         <Col xs={24} className="gx-text-center gx-mb-5">
            <img
               alt=""
               src="/assets/images/logo-small.png"
               style={{ maxWidth: "70vw" }}
            />
         </Col>
      </Row>
   );
};

export default Header;
