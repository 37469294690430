import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { QuizQuestionType } from "../../constants/Enums";
import { useMutation } from "@apollo/client";
import { GQL_QUIZ_COMPILE } from "../../apollo/mutation/quiz";
import { getFormItemContent } from "./functions";

const QuizCompileModal = ({ quiz, onClose }) => {
   const [compileMutation, { loading, error }] = useMutation(GQL_QUIZ_COMPILE);
   const [form] = Form.useForm();
   const intl = useIntl();

   if (!quiz) {
      return null;
   }

   if (error) {
      message.error(error.message);
      return null;
   }

   const handleOk = (values) => {
      const regex = /^-?\d+$/;
      const questionIds = Object.keys(values).filter((questionId) =>
         regex.test(questionId)
      );

      const params = {
         quiz_id: quiz.id,
         answers: questionIds.map((questionId) => ({
            question_id: questionId,
            answer: `${values[questionId]}`, //converte sempre in stringa il risultato
            comment: values[`${questionId}-comment`],
         })),
      };

      compileMutation({ variables: { input: params } })
         .then(() => {
            onClose();
         })
         .catch((error) => message.error(error.message));
   };

   const handleError = (error) => {
      console.log("Error: ", error);
   };

   const { questions } = quiz;

   return (
      <Form
         form={form}
         onFinish={handleOk}
         onFinishFailed={handleError}
         layout="vertical"
         autoComplete="off"
         labelCol={{ span: 24 }}
      >
         <Modal
            visible={quiz}
            title={quiz?.name}
            onCancel={onClose}
            destroyOnClose={true}
            maskClosable={false}
            width={Math.min(window.innerWidth - 100, 1300)}
            footer={[
               <Button key="back" onClick={onClose}>
                  {intl.formatMessage({ id: "common.cancel" })}
               </Button>,
               <Button
                  key="submit"
                  htmlType="submit"
                  type="default"
                  className="gx-bg-success gx-text-white"
                  loading={loading}
                  onClick={(e) => {
                     e.preventDefault();
                     form.submit();
                  }}
               >
                  {intl.formatMessage({ id: "common.confirm" })}
               </Button>,
            ]}
         >
            <Row gutter={80}>
               {questions.map((question, idx) => (
                  <Col lg={8} md={12} sm={24} key={`question-col-${idx}`}>
                     <Form.Item
                        label={question.description}
                        labelCol={{
                           span: 24,
                           style: {
                              whiteSpace: "normal",
                              height: "auto",
                              overflow: "visible",
                           },
                        }}
                        name={question.id}
                        rules={[
                           {
                              required: true,
                              message: intl.formatMessage({
                                 id: "validation.required",
                              }),
                           },
                        ]}
                     >
                        {getFormItemContent(question, intl)}
                     </Form.Item>

                     <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                           prevValues[question.id] !== currentValues[question.id]
                        }
                     >
                        {({ getFieldValue }) => {
                           const val =
                              QuizQuestionType.BOOL_YES_JUSTIFY ||
                              QuizQuestionType.BOOL_NO_JUSTIFY
                                 ? getFieldValue(question.id)
                                 : null;

                           const show =
                              (question.type === QuizQuestionType.BOOL_YES_JUSTIFY &&
                                 val === true) ||
                              (question.type === QuizQuestionType.BOOL_NO_JUSTIFY &&
                                 val === false);

                           return show ? (
                              <Form.Item
                                 labelCol={{
                                    span: 24,
                                    style: {
                                       whiteSpace: "normal",
                                       height: "auto",
                                       overflow: "visible",
                                    },
                                 }}
                                 name={`${question.id}-comment`}
                                 rules={[
                                    {
                                       required: true,
                                       message: intl.formatMessage({
                                          id: "validation.required",
                                       }),
                                    },
                                 ]}
                              >
                                 <Input.TextArea
                                    rows={3}
                                    maxLength={1000}
                                    showCount
                                 />
                              </Form.Item>
                           ) : null;
                        }}
                     </Form.Item>
                  </Col>
               ))}
            </Row>
         </Modal>
      </Form>
   );
};

export default QuizCompileModal;
