export const DATE_FORMAT = "DD-MM-YYYY";

export const DATETIME_FORMAT = "DD-MM-YYYY HH:mm";

export const TIME_FORMAT = "HH:mm";

export const PASSWORD_MIN_LENGTH = 5;
export const PASSWORD_MAX_LENGTH = 50;

export const ADMIN_ROUTE_PREFIX = "admin";

export const VAT_NUMBER_REGEX_PATTERN = "^[0-9]{11}$";
export const FISCAL_CODE_REGEX_PATTERN =
   "^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$";
