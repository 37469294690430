import { gql } from "@apollo/client";

export const GQL_QUERY_ME = gql`
   query me {
      me {
         id
         first_name
         last_name
         full_name
         email
         user_type
         user_types
         manageable_clients_count
         current_client {
            id
            company_name
         }
         smtp_from_address
         smtp_host
         smtp_port
         smtp_username
         smtp_password
         smtp_encryption
      }
   }
`;

export const GQL_INIT_QUERY = gql`
   query initQuery {
      me {
         id
         first_name
         last_name
         full_name
         email
         user_type
         acl_permissions {
            subject
            action
         }
      }
   }
`;

export const GQL_GET_USER = gql`
   query user($id: ID!) {
      me {
         id
         user_types
      }
      user(id: $id) {
         id
         first_name
         last_name
         full_name
         email
         email_verified_at
         active
         user_type
         acl_permissions {
            subject
            action
         }
         manageable_clients {
            id
         }
      }
   }
`;

export const GQL_LOCAL_ME = gql`
   query localMe {
      me @client {
         id
         first_name
         last_name
         full_name
         email
         user_type
         current_client {
            id
            company_name
         }
         manageable_clients_count
      }
   }
`;

export const GQL_PAGINATED_USERS = gql`
   query paginatedUsers(
      $filter: String
      $first: Int
      $page: Int
      $orderBy: OrderByClause
   ) {
      paginatedUsers(
         orderBy: $orderBy
         first: $first
         page: $page
         filter: $filter
      ) {
         data {
            id
            first_name
            last_name
            email
            user_type
            active
            email_verified_at
         }
         paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
         }
      }
   }
`;

export const GQL_ASSIGNABLE_ROLES = gql`
   query localInitQuery {
      me @client {
         assignable_roles
      }
   }
`;

export const GQL_USER_TEST_SMTP = gql`
   query userTestSMTPSettings($input: SMTPUserSettingsInput!) {
      userTestSMTPSettings(input: $input) {
         status
         error
      }
   }
`;
