import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Form, Result, message, Col, Row } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import IntlMessages from "../util/IntlMessages";
import { useMutation } from "@apollo/client";
import { GQL_RESET_PASSWORD } from "../apollo/mutation/user";
import { injectIntl } from "react-intl";
import Header from "./Header";

const ResetPassword = ({ intl }) => {
   const history = useHistory();
   const { search } = useLocation();
   const searchParams = useMemo(() => new URLSearchParams(search), [search]);

   const [success, setSuccess] = useState(false);
   const [loadingMutation, setLoadingMutation] = useState(false);
   const [resetPassword] = useMutation(GQL_RESET_PASSWORD);

   var inputRef = React.createRef();
   useEffect(() => {
      if (inputRef && inputRef.current) {
         inputRef.current.focus();
      }
   }, [inputRef]);

   const email = searchParams.get("email");
   const token = searchParams.get("token");

   if (!email || !token) {
      history.push("/");
      return null;
   }

   const onFinish = (values) => {
      setLoadingMutation(true);

      const params = {
         email,
         token,
         password: values.password1,
         password_confirmation: values.password2,
      };

      resetPassword({ variables: { input: { ...params } } })
         .then(({ data }) => {
            setLoadingMutation(false);

            if (
               data &&
               data.resetPassword &&
               data.resetPassword.status === "PASSWORD_RESET"
            ) {
               setSuccess(true);
            } else {
               message.error(
                  intl.formatMessage({
                     id: "login.reset_password_error_message",
                  })
               );
            }
         })
         .catch((e) => {
            setLoadingMutation(false);
            message.error(
               intl.formatMessage({ id: "login.reset_password_error_message" })
            );
         });
   };

   return (
      <div className="gx-container-fluid bg-blue mh-100vh form-login-container">
         <div className="gx-container frontend-container">
            <Header />

            {!success && (
               <Row>
                  <Col
                     xl={{ span: 8, offset: 8 }}
                     lg={{ span: 12, offset: 6 }}
                     md={{ span: 18, offset: 3 }}
                     sm={{ span: 24, offset: 0 }}
                  >
                     <div className="gx-w-100 gx-text-left mb-100" id="login-form">
                        <h3 className="gx-mb-4">
                           <IntlMessages id="common.reset_password_p1" />
                        </h3>
                        <Form
                           layout="vertical"
                           name="basic"
                           onFinish={onFinish}
                           autoComplete="off"
                           className="gx-signin-form gx-form-row0"
                        >
                           <Form.Item
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                                 {
                                    min: 5,
                                    message: intl.formatMessage({
                                       id: "services.min_5_chars",
                                    }),
                                 },
                                 {
                                    max: 50,
                                    message: intl.formatMessage({
                                       id: "services.max_50_chars",
                                    }),
                                 },
                              ]}
                              name="password1"
                           >
                              <Input.Password
                                 placeholder={intl.formatMessage({
                                    id: "common.new_password",
                                 })}
                                 style={{
                                    backgroundColor: "#ffffff",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                 }}
                                 className="gx-text-primary"
                                 ref={inputRef}
                              />
                           </Form.Item>
                           <Form.Item
                              rules={[
                                 {
                                    required: true,
                                    message: intl.formatMessage({
                                       id: "services.new_service_required_field",
                                    }),
                                 },
                                 {
                                    min: 5,
                                    message: intl.formatMessage({
                                       id: "services.min_5_chars",
                                    }),
                                 },
                                 {
                                    max: 50,
                                    message: intl.formatMessage({
                                       id: "services.max_50_chars",
                                    }),
                                 },
                                 ({ getFieldValue }) => ({
                                    validator(_, value) {
                                       if (
                                          !value ||
                                          getFieldValue("password1") === value
                                       ) {
                                          return Promise.resolve();
                                       }
                                       return Promise.reject(
                                          new Error(
                                             intl.formatMessage({
                                                id: "services.password_not_equals",
                                             })
                                          )
                                       );
                                    },
                                 }),
                              ]}
                              name="password2"
                           >
                              <Input.Password
                                 placeholder={intl.formatMessage({
                                    id: "common.new_password_confirm",
                                 })}
                                 style={{
                                    backgroundColor: "#ffffff",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                 }}
                                 className="gx-text-primary"
                              />
                           </Form.Item>

                           <Form.Item>
                              <Button
                                 type="primary"
                                 className="gx-mb-3 gx-bg-secondary btn-f gx-w-100"
                                 htmlType="submit"
                                 name="buttonSubmit"
                                 loading={loadingMutation}
                              >
                                 <strong>
                                    {intl
                                       .formatMessage({
                                          id: "common.reset_password",
                                       })
                                       .toUpperCase()}
                                 </strong>
                              </Button>
                           </Form.Item>

                           <div className="gx-mb-2">
                              <span>
                                 <IntlMessages id="common.or" />
                              </span>
                              &nbsp;
                              <Link to="/">
                                 <u>
                                    <IntlMessages id="login.signIn" />
                                 </u>
                              </Link>
                           </div>
                        </Form>
                     </div>
                  </Col>
               </Row>
            )}

            {success && (
               <div className="gx-app-login-wrap2">
                  <Result
                     key="1"
                     status="success"
                     title={
                        <h1>
                           <IntlMessages id="login.reset_password_success_title" />
                        </h1>
                     }
                     subTitle={
                        <h4>
                           <IntlMessages id="login.reset_password_success_message" />
                        </h4>
                     }
                     extra={[
                        <Link to="/">
                           <Button
                              key="login"
                              className="gx-mt-3 gx-mb-3 gx-bg-secondary gx-text-white btn-f"
                           >
                              {intl
                                 .formatMessage({
                                    id: "login.signIn",
                                 })
                                 .toUpperCase()}
                           </Button>
                        </Link>,
                     ]}
                  />
               </div>
            )}
         </div>
      </div>
   );
};

export default injectIntl(ResetPassword);
