import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateCASLRoute from "../../components/PrivateCASLRoute";
import IntlMessages from "../../util/IntlMessages";
import NotFound from "../../containers/NotFound";
import { useIntl } from "react-intl";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Result } from "antd";
import { MobileOutlined } from "@ant-design/icons";
import { getAdminPath, lazyWithRetry } from "../../util/router";
import { ACLSubject } from "../../constants/Enums";
import { isEmpty } from "lodash";
import ErrorBoundary from "../../components/ErrorBoundary";

//Lazy loading
const Dashboard = lazy(() => import("./dashboard"));
const Workarea = lazyWithRetry(() => import("./workarea"));
const Customers = lazyWithRetry(() => import("./customers"));
const InsertCustomer = lazyWithRetry(() => import("./customers/insert"));
const UpdateCustomer = lazyWithRetry(() => import("./customers/update"));
const InsertTaxAccount = lazyWithRetry(() =>
   import("./customers/tax-accounts/insert")
);
const UpdateTaxAccountContainer = lazyWithRetry(() =>
   import("./customers/tax-accounts/update/index.js")
);
const CustomersComparison = lazyWithRetry(() => import("./customers/comparison"));
const TaxProvider = lazyWithRetry(() => import("./tax-providers"));
const InsertTaxProvider = lazyWithRetry(() => import("./tax-providers/insert"));
const UpdateTaxProvider = lazyWithRetry(() => import("./tax-providers/update"));
const Users = lazyWithRetry(() => import("./users"));
const UserInsert = lazyWithRetry(() => import("./users/insert"));
const UserUpdate = lazyWithRetry(() => import("./users/update"));
const ProfileSettings = lazyWithRetry(() => import("./profile-settings"));
const Settings = lazyWithRetry(() => import("./settings"));
const Assets = lazyWithRetry(() => import("./assets"));
const AssetUpdate = lazyWithRetry(() => import("./assets/update"));
const AssetView = lazyWithRetry(() => import("./assets/view"));
const Templates = lazyWithRetry(() => import("./templates"));
const TemplateInsert = lazyWithRetry(() => import("./templates/insert"));
const TemplateUpdate = lazyWithRetry(() => import("./templates/update"));
const ActivityMonitor = lazyWithRetry(() => import("./activity-monitor"));
const Logs = lazyWithRetry(() => import("./logs"));

const PrivateTaxProvider = lazyWithRetry(() => import("./private-tax-providers"));
const PrivateTaxProviderInsert = lazyWithRetry(() =>
   import("./private-tax-providers/insert")
);
const PrivateTaxProviderUpdate = lazyWithRetry(() =>
   import("./private-tax-providers/update")
);
const Charities = lazyWithRetry(() => import("./charities"));
const CharitiesInsert = lazyWithRetry(() => import("./charities/insert"));
const CharitiesUpdate = lazyWithRetry(() => import("./charities/update"));
//

const DataAnalysis = lazyWithRetry(() => import("./data-analysis/index"));
const Reports = lazyWithRetry(() => import("./reports/index"));
const Estimates = lazyWithRetry(() => import("./estimates/index"));
const EstimateRequestUpdate = lazyWithRetry(() =>
   import("./estimates/requests/update")
);

const CRMCalendar = lazyWithRetry(() => import("./crm/calendar"));
const CRMContacts = lazyWithRetry(() => import("./crm/contacts"));
const CRMContactsUpdate = lazyWithRetry(() =>
   import("./crm/contacts/update/index.js")
);

const CRMNegotiations = lazyWithRetry(() => import("./crm/negotiations"));
const CRMNegotiationsInsert = lazyWithRetry(() =>
   import("./crm/negotiations/insert")
);
const CRMNegotiationsUpdate = lazyWithRetry(() =>
   import("./crm/negotiations/update")
);
const CRMNegotiationsNewMessageOut = lazyWithRetry(() =>
   import("./crm/negotiations/new-message/out")
);
const CRMNegotiationsNewMessageIn = lazyWithRetry(() =>
   import("./crm/negotiations/new-message/in")
);
const CRMSettings = lazyWithRetry(() => import("./crm/settings"));
const CRMTemplates = lazyWithRetry(() => import("./crm/templates"));
const CRMTemplateInsert = lazyWithRetry(() => import("./crm/templates/insert"));
const CRMTemplateUpdate = lazyWithRetry(() => import("./crm/templates/update"));

const Plant = lazyWithRetry(() => import("./plants"));
const PlantInsert = lazyWithRetry(() => import("./plants/insert"));
const PlantUpdate = lazyWithRetry(() => import("./plants/update"));

const Admin = () => {
   const intl = useIntl();

   const screens = useBreakpoint();

   //isEmpty per assicurarsi che screens sia valorizzato altrimenti inizialmente a vuoto mostra per una microfrazione il componente.
   if (!isEmpty(screens) && !screens.md) {
      return (
         <Result
            icon={<MobileOutlined />}
            status="warning"
            title={intl.formatMessage({ id: "common.viewport_error" })}
         />
      );
   }

   return (
      <ErrorBoundary>
         <Suspense fallback={<IntlMessages id="common.loading" />}>
            <Switch>
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CLIENT}
                  exact
                  path={getAdminPath("dashboard")}
                  component={Dashboard}
               />
               <PrivateCASLRoute
                  I="read"
                  a={ACLSubject.CLIENT}
                  path={getAdminPath("workarea")}
                  component={Workarea}
               />
               <Route
                  exact
                  path={getAdminPath("profile")}
                  component={ProfileSettings}
               />
               {/* ADMIN */}
               <PrivateCASLRoute
                  I="read"
                  a={ACLSubject.CLIENT}
                  exact
                  path={getAdminPath("customers")}
                  component={Customers}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("tax-providers")}
                  component={TaxProvider}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.CLIENT}
                  exact
                  path={getAdminPath("customers/insert")}
                  component={InsertCustomer}
               />
               <PrivateCASLRoute
                  I="update"
                  a={ACLSubject.CLIENT}
                  exact
                  path={getAdminPath("customers/update/:id")}
                  component={UpdateCustomer}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.TAX_ACCOUNT}
                  exact
                  path={getAdminPath("customers/:customerId/tax-account/insert")}
                  component={InsertTaxAccount}
               />
               <PrivateCASLRoute
                  I="update"
                  a={ACLSubject.TAX_ACCOUNT}
                  exact
                  path={getAdminPath("customers/:customerId/tax-account/update/:id")}
                  component={UpdateTaxAccountContainer}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.CLIENT}
                  exact
                  path={getAdminPath("customers/comparison")}
                  component={CustomersComparison}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("tax-providers")}
                  component={TaxProvider}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("tax-providers/insert")}
                  component={InsertTaxProvider}
               />
               <PrivateCASLRoute
                  I="update"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("tax-providers/update/:id")}
                  component={UpdateTaxProvider}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.SETTING}
                  exact
                  path={getAdminPath("settings")}
                  component={Settings}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("users")}
                  component={Users}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("users/insert")}
                  component={UserInsert}
               />
               <PrivateCASLRoute
                  I="update"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("users/update/:itemId")}
                  component={UserUpdate}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.ASSET}
                  exact
                  path={getAdminPath("assets")}
                  component={Assets}
               />
               <PrivateCASLRoute
                  I="read"
                  a={ACLSubject.CLIENT_ASSET}
                  exact
                  path={getAdminPath("assets/view/:itemId")}
                  component={AssetView}
               />
               <PrivateCASLRoute
                  I="update"
                  a={ACLSubject.CLIENT_ASSET}
                  exact
                  path={getAdminPath("assets/update/:itemId")}
                  component={AssetUpdate}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.TEMPLATE}
                  exact
                  path={getAdminPath("templates")}
                  component={Templates}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.TEMPLATE}
                  exact
                  path={getAdminPath("templates/insert")}
                  component={TemplateInsert}
               />
               <PrivateCASLRoute
                  I="update"
                  a={ACLSubject.TEMPLATE}
                  exact
                  path={getAdminPath("templates/update/:itemId")}
                  component={TemplateUpdate}
               />
               <PrivateCASLRoute
                  I="list"
                  a={ACLSubject.DOSSIER}
                  exact
                  path={getAdminPath("activity-monitor")}
                  component={ActivityMonitor}
               />
               <PrivateCASLRoute
                  I="list"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("logs")}
                  component={Logs}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("privated-tax-providers")}
                  component={PrivateTaxProvider}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("privated-tax-providers/insert")}
                  component={PrivateTaxProviderInsert}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("privated-tax-providers/update/:id")}
                  component={PrivateTaxProviderUpdate}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CHARITY}
                  exact
                  path={getAdminPath("charities")}
                  component={Charities}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.CHARITY}
                  exact
                  path={getAdminPath("charities/insert")}
                  component={CharitiesInsert}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CHARITY}
                  exact
                  path={getAdminPath("charities/update/:id")}
                  component={CharitiesUpdate}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.DATA_ANALYSIS}
                  exact
                  path={getAdminPath("data-analysis")}
                  component={DataAnalysis}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.REPORT}
                  exact
                  path={getAdminPath("reports")}
                  component={Reports}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.ESTIMATE}
                  exact
                  path={getAdminPath("estimates")}
                  component={Estimates}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.ESTIMATE}
                  exact
                  path={getAdminPath("estimates/requests/update/:id")}
                  component={EstimateRequestUpdate}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/calendar")}
                  component={CRMCalendar}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/contacts")}
                  component={CRMContacts}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/contacts/update/:id")}
                  component={CRMContactsUpdate}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/negotiations")}
                  component={CRMNegotiations}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/negotiations/insert")}
                  component={CRMNegotiationsInsert}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/negotiations/update/:id")}
                  component={CRMNegotiationsUpdate}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath(
                     "crm/negotiations/new-message-out/:negotiationId/:contactId"
                  )}
                  component={CRMNegotiationsNewMessageOut}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath(
                     "crm/negotiations/new-message-in/:negotiationId/:contactId"
                  )}
                  component={CRMNegotiationsNewMessageIn}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/settings")}
                  component={CRMSettings}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/templates")}
                  component={CRMTemplates}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/templates/insert")}
                  component={CRMTemplateInsert}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.CRM_CONTACT}
                  exact
                  path={getAdminPath("crm/templates/update/:id")}
                  component={CRMTemplateUpdate}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("plants")}
                  component={Plant}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("plants/insert")}
                  component={PlantInsert}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.TAX_PROVIDER}
                  exact
                  path={getAdminPath("plants/update/:id")}
                  component={PlantUpdate}
               />

               <Route path="*" component={NotFound} />
            </Switch>
         </Suspense>
      </ErrorBoundary>
   );
};

export default Admin;
