import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GQL_QUIZ_TO_COMPILE } from "../../apollo/query/quiz";
import { get } from "lodash";
import { Button, notification } from "antd";
import { useIntl } from "react-intl";
import { SmileOutlined } from "@ant-design/icons";
import QuizCompileModal from "./quiz-compile-modal";

const NOTIFICATION_QUIZ_KEY = "quiz-to-compile";

const Notification = () => {
   const [retrieveQuizToCompile] = useLazyQuery(GQL_QUIZ_TO_COMPILE);
   const intl = useIntl();
   const [quizToCompile, setQuizToCompile] = useState(null);

   useEffect(() => {
      retrieveQuizToCompile().then((res) => {
         const data = get(res, "data.quizToCompile");
         if (data) {
            notification.info({
               message: intl.formatMessage({ id: "quiz.platform_evaluation" }),
               description: data.name,
               duration: 0,
               key: NOTIFICATION_QUIZ_KEY,
               placement: "bottomRight",
               bottom: 50,
               maxCount: 1,
               icon: <SmileOutlined className="gx-text-success" />,
               btn: (
                  <Button
                     type="primary"
                     className="gx-mb-0"
                     onClick={() => {
                        notification.close(NOTIFICATION_QUIZ_KEY);
                        setQuizToCompile(data);
                     }}
                  >
                     {intl.formatMessage({ id: "quiz.compile" })}
                  </Button>
               ),
            });
         }
      });
   }, []);

   return (
      <>
         <QuizCompileModal
            quiz={quizToCompile}
            onClose={() => setQuizToCompile(null)}
         />
      </>
   );
};

export default Notification;
