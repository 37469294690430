import {
   createHttpLink,
   ApolloLink,
   ApolloClient,
   from,
   split,
   Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import apolloCache from "./cache";
import { localStorageClass } from "./local/storage";
import { print } from "graphql";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient as createWsClient } from "graphql-ws";
import { get } from "lodash";

const httpLink = createHttpLink({
   uri: process.env.REACT_APP_APOLLO_SERVER_URL,
   credentials: "same-origin",
   headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
         "Origin, X-Requested-With, Content-Type, Accept",
   },
});

class WebSocketLink extends ApolloLink {
   client;

   constructor(options) {
      super();
      this.client = createWsClient(options);
   }

   request(operation) {
      return new Observable((sink) => {
         return this.client.subscribe(
            { ...operation, query: print(operation.query) },
            {
               next: sink.next.bind(sink),
               complete: sink.complete.bind(sink),
               error: (err) => {
                  if (Array.isArray(err))
                     // GraphQLError[]
                     return sink.error(
                        new Error(err.map(({ message }) => message).join(", "))
                     );

                  if (err instanceof CloseEvent)
                     return sink.error(
                        new Error(
                           `Socket closed with event ${err.code} ${err.reason || ""}` // reason will be available on clean closes only
                        )
                     );

                  return sink.error(err);
               },
            }
         );
      });
   }
}

const wsLink = new WebSocketLink({
   url: process.env.REACT_APP_APOLLO_SERVER_WS_URL,
   connectionParams: async () => {
      const token = await getAuthToken();

      if (!token) {
         return {};
      }
      return {
         authorization: `Bearer ${token}`,
      };
   },
});

const getAuthToken = async () => {
   if (!localStorageClass.isLogged()) {
      return null;
   }

   return localStorageClass.getToken();
};

// add the authorization to the headers
const authMiddleware = new ApolloLink(async (operation, forward) => {
   const token = await getAuthToken();

   if (token) {
      operation.setContext({
         headers: {
            authorization: `Bearer ${token}`,
         },
      });

      return forward(operation);
   }

   return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
   if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
         const category = get(error, "extensions.category");
         if (category == "authentication") {
            if (localStorageClass.isLogged()) {
               localStorageClass.logout();
               window.location.reload();
               return;
            }
         }
      });
   }

   if (networkError) {
      console.log(`[Network error]: ${networkError}`);
   }
});

const splitLink = split(
   ({ query }) => {
      const definition = getMainDefinition(query);
      return (
         definition.kind === "OperationDefinition" &&
         definition.operation === "subscription"
      );
   },
   wsLink,
   httpLink
);

const apolloClient = new ApolloClient({
   cache: apolloCache,
   connectToDevTools: true,
   defaultOptions: {
      watchQuery: {
         fetchPolicy: "cache-and-network",
         errorPolicy: "all",
      },
      query: {
         errorPolicy: "all",
      },
      mutate: {
         errorPolicy: "all",
      },
   },
   link: from([authMiddleware, errorLink, splitLink]),
});

export default apolloClient;
