import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, message, Form, Row, Col } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "../util/IntlMessages";
import { useMutation } from "@apollo/client";
import { GQL_LOGIN } from "../apollo/mutation/user";
import { injectIntl } from "react-intl";
import { localStorageClass } from "../apollo/local/storage";
import { get } from "lodash";
import Header from "../containers/Header";
import { getAdminPath } from "../util/router";

const SignIn = ({ intl }) => {
   const [login] = useMutation(GQL_LOGIN);

   const [loading, setLoading] = useState(false);
   const [checked, setChecked] = useState(false);
   const history = useHistory();

   let paramRememberMe = { email: "", password: "" };
   const localParam = localStorage.getItem("REMEMBER_ME");
   if (localParam) {
      const jsonObj = JSON.parse(localParam);
      if (jsonObj) {
         paramRememberMe = jsonObj;
      }
   }

   useEffect(() => {
      if (localStorageClass.isLogged()) {
         history.push(getAdminPath());
      } else {
         const email = get(paramRememberMe, "email", "");
         if (!checked && email.length > 0) {
            setChecked(true);
         }
      }
   }, []);

   const rememberMeChecked = (e) => {
      if (!e.target.checked) {
         localStorage.setItem("REMEMBER_ME", null);
      }

      setChecked(e.target.checked);
   };

   const onFinish = (values) => {
      setLoading(true);
      const params = {
         input: {
            email: values.email.toLowerCase(),
            password: values.password,
         },
      };
      login({ variables: params })
         .then(({ data, errors }) => {
            if (data && data.login) {
               localStorage.setItem(
                  "REMEMBER_ME",
                  checked ? JSON.stringify(params.input) : null
               );

               localStorageClass.login(data.login);
               setLoading(false);

               history.push(getAdminPath());
            } else {
               const messageStringError = get(
                  errors,
                  "[0].message",
                  "server_error.login.unauthorized"
               );
               message.error(intl.formatMessage({ id: messageStringError }));
               setLoading(false);
            }
         })
         .catch((e) => {
            message.error(intl.formatMessage({ id: "login.signInError" }));
            setLoading(false);
         });
   };

   var inputRef = React.createRef();
   useEffect(() => {
      if (inputRef && inputRef.current) {
         inputRef.current.focus();
      }
   }, [inputRef]);

   return (
      <div className="gx-container-fluid mh-100vh form-login-container">
         <div className="gx-container frontend-container">
            <Header />
            <Row>
               <Col
                  xl={{ span: 8, offset: 8 }}
                  lg={{ span: 12, offset: 6 }}
                  md={{ span: 18, offset: 3 }}
                  sm={{ span: 24, offset: 0 }}
               >
                  <div className="gx-w-100 gx-text-left gx-m-0" id="login-form">
                     <Form
                        layout="vertical"
                        initialValues={{ remember: true }}
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="on"
                        className="gx-signin-form gx-form-row0"
                     >
                        <Form.Item
                           required={false}
                           initialValue={paramRememberMe.email}
                           label={<IntlMessages id="common.email" />}
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "validation.required",
                                 }),
                              },
                           ]}
                           name="email"
                        >
                           <Input
                              placeholder={intl.formatMessage({
                                 id: "common.email",
                              })}
                              type="text"
                              style={{
                                 backgroundColor: "#ffffff",
                              }}
                              ref={inputRef}
                           />
                        </Form.Item>
                        <Form.Item
                           required={false}
                           initialValue={paramRememberMe.password}
                           label={<IntlMessages id="login.password" />}
                           rules={[
                              {
                                 required: true,
                                 message: intl.formatMessage({
                                    id: "validation.required",
                                 }),
                              },
                           ]}
                           name="password"
                        >
                           <Input.Password
                              type="password"
                              style={{
                                 backgroundColor: "#ffffff",
                                 paddingTop: "0px",
                                 paddingBottom: "0px",
                              }}
                              className="gx-text-primary"
                              placeholder={intl.formatMessage({
                                 id: "login.password",
                              })}
                              autoComplete="on"
                           />
                        </Form.Item>
                        <Form.Item name="ckRememberMe">
                           <Checkbox checked={checked} onChange={rememberMeChecked}>
                              <IntlMessages id="common.remember_me" />
                           </Checkbox>
                        </Form.Item>

                        <Form.Item>
                           <Button
                              type="primary"
                              className="gx-mb-3 gx-bg-secondary btn-f gx-w-100"
                              htmlType="submit"
                              name="buttonSubmit"
                              loading={loading}
                           >
                              <strong>
                                 {intl
                                    .formatMessage({
                                       id: "common.login",
                                    })
                                    .toUpperCase()}
                              </strong>
                           </Button>
                        </Form.Item>

                        <div className="gx-mb-2">
                           <IntlMessages id="login.forgot_password1" />
                           &nbsp;
                           <Link
                              className="gx-login-form-forgot"
                              to="forget-password"
                           >
                              <u>
                                 <IntlMessages id="login.forgot_password2" />
                              </u>
                              &nbsp;
                           </Link>
                           <IntlMessages id="login.forgot_password3" />
                        </div>
                     </Form>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col xs={24} className="gx-mt-4">
                  {process.env.REACT_APP_GIT_VERSION && (
                     <small className="gx-ml-1 gx-text-grey">
                        v{process.env.REACT_APP_GIT_VERSION.substring(0, 7)}
                     </small>
                  )}
               </Col>
            </Row>
         </div>
      </div>
   );
};

export default injectIntl(SignIn);
