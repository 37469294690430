import { gql } from "@apollo/client";

export const GQL_FEATURE_OVERVIEW_UPSERT = gql`
   mutation upsertFeatureOverview($input: UpsertFeatureOverviewInput!) {
      upsertFeatureOverview(input: $input) {
         id
      }
   }
`;

export const GQL_FEATURE_OVERVIEW_VIEWS = gql`
   mutation setFeatureOverviewView($ids: [ID]!) {
      setFeatureOverviewView(ids: $ids)
   }
`;

export const GQL_FEATURE_OVERVIEW_DELETE = gql`
   mutation deleteFeatureOverview($id: ID!) {
      deleteFeatureOverview(id: $id)
   }
`;
