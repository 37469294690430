import { get } from "lodash";

//User logged settings
const LOCAL_STORAGE_KEY = "RTARI_CLIENT_USER";

class LocalStorageClass {
   login(params) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(params));
   }

   logout() {
      //localStorage.setItem(LOCAL_STORAGE_KEY, null);
      localStorage.clear();
   }

   isLogged() {
      const item = this._parseJSONStorageItem(LOCAL_STORAGE_KEY);
      return item != null;
   }

   getToken() {
      return this.getField("token") ?? "";
   }

   setToken(token, expires_at) {
      const jsonItem = {
         ...this._parseJSONStorageItem(LOCAL_STORAGE_KEY),
         token,
         expires_at,
      };
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(jsonItem));
   }

   getRefreshToken() {
      return this.getField("refresh_token");
   }

   setRefreshToken(newRefreshToken) {
      var jsonItem = { ...this._parseJSONStorageItem(LOCAL_STORAGE_KEY) };
      jsonItem.refresh_token = newRefreshToken;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(jsonItem));
   }

   getField(key) {
      const jsonItem = this._parseJSONStorageItem(LOCAL_STORAGE_KEY);
      return get(jsonItem, key, null);
   }

   _parseJSONStorageItem(key) {
      const item = localStorage.getItem(key);
      if (item) {
         try {
            return JSON.parse(item);
         } catch (e) {
            return null;
         }
      }
      return null;
   }
}

export const localStorageClass = new LocalStorageClass();
Object.freeze(localStorageClass); //Single ton
//
