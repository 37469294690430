import { gql } from "@apollo/client";

export const GQL_PAGINATED_FEATURES_OVERVIEW = gql`
   query paginatedFeaturesOverview(
      $filter: String
      $first: Int
      $page: Int
      $orderBy: OrderByClause
   ) {
      paginatedFeaturesOverview(
         filter: $filter
         first: $first
         page: $page
         orderBy: $orderBy
      ) {
         data {
            id
            feature_date
            title
            description
            visible_to_roles
         }
         paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
         }
      }
   }
`;

export const GQL_FEATURES_OVERVIEW_USER = gql`
   query featuresOverview {
      featuresOverview {
         id
         feature_date
         title
         description
         visible_to_roles
         overview_user {
            user {
               id
            }
         }
      }
   }
`;
