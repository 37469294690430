import { gql } from "@apollo/client";

export const GQL_LOGIN = gql`
   mutation login($input: LoginInput) {
      login(input: $input) {
         token
      }
   }
`;

export const GQL_LOGOUT = gql`
   mutation logout {
      logout {
         status
      }
   }
`;

export const GQL_FORGOT_PASSWORD = gql`
   mutation forgotPassword($input: ForgotPasswordInput!) {
      forgotPassword(input: $input) {
         status
         message
      }
   }
`;

export const GQL_RESET_PASSWORD = gql`
   mutation resetPassword($input: ResetPasswordInput!) {
      resetPassword(input: $input) {
         status
         message
      }
   }
`;

export const GQL_VERIFY_USER = gql`
   mutation verifyUser($token: String!) {
      verifyUser(token: $token) {
         id
      }
   }
`;

export const GQL_USER_UPDATE = gql`
   mutation updateUser($user: UpdateUserInput!) {
      updateUser(user: $user) {
         status
      }
   }
`;

export const GQL_USER_UPDATE_PASSWORD = gql`
   mutation updatePassword($input: UpdatePasswordInput!) {
      updatePassword(input: $input) {
         status
      }
   }
`;

export const GQL_USER_DELETE = gql`
   mutation deleteUser($id: ID!) {
      deleteUser(id: $id) {
         status
      }
   }
`;

export const GQL_USER_ADD_NEW = gql`
   mutation createUser($user: CreateUserInput!) {
      createUser(user: $user)
   }
`;

export const GQL_ADMIN_USER_ADD_NEW = gql`
   mutation adminCreateUser($user: AdminCreateUserInput!) {
      adminCreateUser(user: $user)
   }
`;

export const GQL_ADMIN_USER_UPDATE = gql`
   mutation adminUpdateUser($input: AdminUpdateUserInput!) {
      adminUpdateUser(input: $input) {
         status
      }
   }
`;

export const GQL_ADMIN_USER_CREATE = gql`
   mutation adminCreateUser($input: AdminCreateUserInput!) {
      adminCreateUser(input: $input) {
         status
      }
   }
`;

export const GQL_ADMIN_USER_SET_CURRENT_CUSTOMER = gql`
   mutation updateUserCurrentClient($client_id: String, $first: Boolean) {
      updateUserCurrentClient(client_id: $client_id, first: $first) {
         status
      }
   }
`;

export const GQL_USER_UPDATE_SMTP = gql`
   mutation updateUserSMTPSettings($input: SMTPUserSettingsInput!) {
      updateUserSMTPSettings(input: $input) {
         status
      }
   }
`;
