import React, { useContext, useEffect, useRef, useState } from "react";
import { Badge, Divider, Menu, Skeleton, Typography } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { layoutSettingsVar } from "../../apollo/local/cache";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Can } from "../../acl/index";
import { get, startsWith } from "lodash";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAdminPath } from "../../util/router";
import {
   faUsersLine,
   faGauge,
   faBoxArchive,
   faGears,
   faBuildingColumns,
   faRecycle,
   faMailBulk,
   faServer,
   faDumpster,
   faHandHoldingHeart,
   faXmarksLines,
   faMagnifyingGlassChart,
   faBook,
   faSignHanging,
   faProjectDiagram,
   faCalendarDays,
   faHands,
   faIndustry,
} from "@fortawesome/free-solid-svg-icons";
import { ADMIN_ROUTE_PREFIX } from "../../constants/App";
import CustomerSelect from "../../components/CustomerSelect";
import SidebarCustomerContent from "./SidebarCustomerContent";
import { GQL_QUERY_ME } from "../../apollo/query/user";
import { ACLSubject } from "../../constants/Enums";
import { GQL_ADMIN_USER_SET_CURRENT_CUSTOMER } from "../../apollo/mutation/user";
import { AdminContext } from "../App/AdminApp";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, intl }) => {
   const adminContext = useContext(AdminContext);

   //assicura first load senza ricaricare la sidebar
   const sidebarLoaded = useRef(null);

   const { data, loading, refetch } = useQuery(GQL_QUERY_ME, {
      onCompleted() {
         if (!sidebarLoaded.current) {
            sidebarLoaded.current = true;
         }
      },
   });

   const [activeCustomerLoading, setActiveCustomerLoading] = useState(false);

   const [updateCurrentCustomer] = useMutation(GQL_ADMIN_USER_SET_CURRENT_CUSTOMER);

   let { themeType } = useReactiveVar(layoutSettingsVar);

   //Selected menu item highlight
   const pathname = window.location.pathname;
   let selectedKey = "";

   const menuKeys = [
      `/${ADMIN_ROUTE_PREFIX}/dashboard`,
      `/${ADMIN_ROUTE_PREFIX}/customers`,
      `/${ADMIN_ROUTE_PREFIX}/tax-providers`,
      `/${ADMIN_ROUTE_PREFIX}/assets`,
      `/${ADMIN_ROUTE_PREFIX}/users`,
      `/${ADMIN_ROUTE_PREFIX}/settings`,
   ];

   menuKeys.forEach((key) => {
      if (pathname === key || startsWith(pathname, key + "/")) {
         selectedKey = key;
         return;
      }
   });
   //

   const me = get(data, "me", null);

   useEffect(() => {
      if (me && me?.manageable_clients_count === 1 && !me.current_client?.id) {
         //se ha accesso ad un cliente ma non è selezionato forza la selezione
         updateCurrentCustomer({ variables: { first: true } }).then(() => {
            refetch();
         });
      }
   }, [me]);

   if (!sidebarLoaded.current && loading) {
      return <Skeleton active={true} />;
   }

   return (
      <>
         <SidebarLogo
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
         />

         <div
            className="gx-sidebar-content"
            style={{ height: "calc(100vh - 72px)" }}
         >
            <CustomScrollbars className="gx-layout-sider-scrollbar_todo">
               <Menu
                  selectedKeys={[selectedKey]}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  style={{ overflowX: "hidden" }}
               >
                  <Can I="manage" a={ACLSubject.CLIENT}>
                     <Menu.ItemGroup title={null}>
                        <Menu.Item key={getAdminPath("dashboard")}>
                           <Link to={getAdminPath("dashboard")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faGauge} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.dashboard" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Menu.ItemGroup>
                  </Can>
                  <Menu.ItemGroup
                     title={
                        me.manageable_clients_count > 1 ? (
                           intl
                              .formatMessage({ id: "sidebar.working_area" })
                              .toUpperCase()
                        ) : (
                           <Typography.Title level={4} className="gx-mt-3">
                              {me.current_client?.company_name}
                           </Typography.Title>
                        )
                     }
                  >
                     {me.manageable_clients_count > 1 && (
                        <div className="gx-pt-3 gx-pl-4 gx-pr-4">
                           <CustomerSelect
                              value={me?.current_client?.id}
                              style={{ width: "100%" }}
                              onLoading={(value) => {
                                 setActiveCustomerLoading(value);
                              }}
                              refreshPageAfterMutation={true}
                           />
                        </div>
                     )}

                     {!activeCustomerLoading ? (
                        <SidebarCustomerContent
                           client={get(me, "current_client", null)}
                           key="mn-sc-content"
                        />
                     ) : (
                        <Skeleton
                           className="gx-m-4"
                           style={{ maxWidth: "80%" }}
                           active={true}
                        />
                     )}
                  </Menu.ItemGroup>

                  <Menu.ItemGroup
                     title={intl
                        .formatMessage({ id: "sidebar.admin" })
                        .toUpperCase()}
                  >
                     <Can I="update" a={ACLSubject.CLIENT}>
                        <Menu.Item key={getAdminPath("customers")}>
                           <Link to={getAdminPath("customers")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faBuildingColumns} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.customers" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Can I="manage" a={ACLSubject.TAX_PROVIDER}>
                        <Menu.Item key={getAdminPath("tax-providers")}>
                           <Link to={getAdminPath("tax-providers")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faRecycle} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.tax_providers" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Can I="manage" a={ACLSubject.TAX_PROVIDER}>
                        <Menu.Item key={getAdminPath("privated-tax-providers")}>
                           <Link to={getAdminPath("privated-tax-providers")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faDumpster} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.private_tax_providers" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={getAdminPath("plants")}>
                           <Link to={getAdminPath("plants")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faIndustry} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.plants" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Can I="manage" a={ACLSubject.CHARITY}>
                        <Menu.Item key={getAdminPath("charities")}>
                           <Link to={getAdminPath("charities")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faHandHoldingHeart} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.charities" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Can I="manage" a={ACLSubject.ASSET}>
                        <Menu.Item key={getAdminPath("assets")}>
                           <Link to={getAdminPath("assets")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faBoxArchive} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.generic_assets" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Can I="manage" a={ACLSubject.REPORT}>
                        <Menu.Item key={getAdminPath("reports")}>
                           <Link to={getAdminPath("reports")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faBook} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.reports" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Can I="manage" a={ACLSubject.ESTIMATE}>
                        <Menu.Item key={getAdminPath("estimates")}>
                           <Link to={getAdminPath("estimates")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faSignHanging} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.estimates" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Divider key="divider-sidebar-menu" />

                     <Can I="manage" a={ACLSubject.USER}>
                        <Menu.Item key={getAdminPath("users")}>
                           <Link to={getAdminPath("users")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faUsersLine} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.users" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Can I="manage" a={ACLSubject.TEMPLATE}>
                        <Menu.Item key={getAdminPath("templates")}>
                           <Link to={getAdminPath("templates")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faMailBulk} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.templates" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>

                     <Can I="manage" a={ACLSubject.SETTING}>
                        <Menu.Item key={getAdminPath("settings")}>
                           <Link to={getAdminPath("settings")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faGears} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.settings" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Can>
                  </Menu.ItemGroup>

                  <Can I="manage" a={ACLSubject.USER || ACLSubject.DATA_ANALYSIS}>
                     <Divider key="divider-sidebar-menu2" />

                     <Menu.Item key={getAdminPath("data-analysis")}>
                        <Link to={getAdminPath("data-analysis")}>
                           <span className="icon">
                              <FontAwesomeIcon icon={faMagnifyingGlassChart} />
                           </span>
                           <span>
                              <IntlMessages id="sidebar.data_analysis" />
                           </span>
                        </Link>
                     </Menu.Item>

                     <Menu.Item key={getAdminPath("activity-monitor")}>
                        <Link to={getAdminPath("activity-monitor")}>
                           <Badge
                              offset={[5, 0]}
                              count={adminContext?.selectedDossierIds.length}
                           >
                              <span className="icon">
                                 <FontAwesomeIcon icon={faServer} />
                              </span>
                              <span>
                                 <IntlMessages id="dossiers.activity_monitor" />
                              </span>
                           </Badge>
                        </Link>
                     </Menu.Item>

                     <Menu.Item key={getAdminPath("logs")}>
                        <Link to={getAdminPath("logs")}>
                           <span className="icon">
                              <FontAwesomeIcon icon={faXmarksLines} />
                           </span>
                           <span>
                              <IntlMessages id="common.logs" />
                           </span>
                        </Link>
                     </Menu.Item>
                  </Can>

                  <Can I="update" a={ACLSubject.CRM_CONTACT}>
                     <Menu.ItemGroup
                        title={intl
                           .formatMessage({ id: "sidebar.crm" })
                           .toUpperCase()}
                     >
                        <Menu.Item key={getAdminPath("crm/calendar")}>
                           <Link to={getAdminPath("crm/calendar")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faCalendarDays} />
                              </span>
                              <span>
                                 <IntlMessages id="common.calendar" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={getAdminPath("crm/contacts")}>
                           <Link to={getAdminPath("crm/contacts")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faProjectDiagram} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.crm_contacts" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={getAdminPath("crm/negotiations")}>
                           <Link to={getAdminPath("crm/negotiations")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faHands} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.crm_negotiations" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={getAdminPath("crm/templates")}>
                           <Link to={getAdminPath("crm/templates")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faMailBulk} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.templates" />
                              </span>
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={getAdminPath("crm/settings")}>
                           <Link to={getAdminPath("crm/settings")}>
                              <span className="icon">
                                 <FontAwesomeIcon icon={faGears} />
                              </span>
                              <span>
                                 <IntlMessages id="sidebar.settings" />
                              </span>
                           </Link>
                        </Menu.Item>
                     </Menu.ItemGroup>
                  </Can>
               </Menu>
            </CustomScrollbars>
         </div>
      </>
   );
};

SidebarContent.propTypes = {};

export default injectIntl(SidebarContent);
