import React, { createContext, useContext, useEffect, useState } from "react";
import { Layout, Row, Col } from "antd";
import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import AdminRoutes from "routes/index";
import BadResponse from "../BadResponse";
import { get } from "lodash";

import {
   NAV_STYLE_ABOVE_HEADER,
   NAV_STYLE_BELOW_HEADER,
   NAV_STYLE_DARK_HORIZONTAL,
   NAV_STYLE_DEFAULT_HORIZONTAL,
   NAV_STYLE_DRAWER,
   NAV_STYLE_FIXED,
   NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
   NAV_STYLE_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";

import CircularProgress from "../../components/CircularProgress";
import { layoutSettingsVar } from "../../apollo/local/cache";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GQL_INIT_QUERY } from "../../apollo/query/user";
import { AbilityContext, defineRulesFor, isAdmin } from "../../acl/index";
import { injectIntl } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";
import { getAdminPath } from "../../util/router";
import Notification from "../Notification";

export const AdminContext = createContext();

const ProtectedMainApp = ({ user }) => {
   const [selectedDossierIds, setSelectedDossierIds] = useState([]);
   const workspaces = get(user, "current_organization.workspaces", []);

   const ability = useContext(AbilityContext);
   const { navStyle } = useReactiveVar(layoutSettingsVar);
   const location = useLocation();
   const history = useHistory();

   useEffect(() => {
      if (user) {
         const rules = defineRulesFor(user);
         ability.update(rules);
      }
   }, [user, ability]);

   useEffect(() => {
      if (location.pathname === getAdminPath() && user) {
         if (isAdmin(user)) {
            history.replace(getAdminPath("dashboard"));
         } else {
            history.replace(getAdminPath("workarea"));
         }
      }
   }, [location, user]);

   const getContainerClass = (navStyle) => {
      switch (navStyle) {
         case NAV_STYLE_DARK_HORIZONTAL:
            return "gx-container-wrap";
         case NAV_STYLE_DEFAULT_HORIZONTAL:
            return "gx-container-wrap";
         case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
            return "gx-container-wrap";
         case NAV_STYLE_BELOW_HEADER:
            return "gx-container-wrap";
         case NAV_STYLE_ABOVE_HEADER:
            return "gx-container-wrap";
         default:
            return "";
      }
   };
   const getNavStyles = (navStyle) => {
      switch (navStyle) {
         case NAV_STYLE_FIXED:
            return <Topbar />;
         case NAV_STYLE_DRAWER:
            return <Topbar />;
         case NAV_STYLE_MINI_SIDEBAR:
            return <Topbar />;
         default:
            return null;
      }
   };

   return (
      <AdminContext.Provider value={{ selectedDossierIds, setSelectedDossierIds }}>
         <Layout className="gx-app-layout">
            <Sidebar user={user} workspaces={workspaces} />
            <Layout>
               {getNavStyles(navStyle)}
               <Layout.Content
                  className={`gx-layout-content ${getContainerClass(navStyle)} `}
               >
                  <AdminRoutes ability={ability} />
                  <Layout.Footer>
                     <Row>
                        <Col sm={24}>
                           <div className="gx-layout-footer-content gx-text-right">
                              <a
                                 href={process.env.REACT_APP_CREDITS_URL}
                                 target="_bweb"
                                 className="gx-text-grey"
                              >
                                 <small>{process.env.REACT_APP_CREDITS}</small>
                              </a>

                              {process.env.REACT_APP_GIT_VERSION && (
                                 <small className="gx-ml-1 gx-text-grey">
                                    v
                                    {process.env.REACT_APP_GIT_VERSION.substring(
                                       0,
                                       7
                                    )}
                                 </small>
                              )}
                           </div>
                        </Col>
                     </Row>
                     <Notification />
                  </Layout.Footer>
               </Layout.Content>
            </Layout>
         </Layout>
      </AdminContext.Provider>
   );
};

const MainApp = ({ intl }) => {
   const {
      loading: loadingProfile,
      error: errorProfile,
      data,
   } = useQuery(GQL_INIT_QUERY);

   if (loadingProfile) {
      return (
         <div className="gx-loader-view">
            <CircularProgress />
         </div>
      );
   }

   if (errorProfile) {
      return (
         <BadResponse
            title={intl.formatMessage({ id: "500.ooops" })}
            subtitle={intl.formatMessage({ id: "500.something_wrong" })}
         />
      );
   }

   return <ProtectedMainApp user={data.me} />;
};

export default injectIntl(MainApp);
