import moment from "moment";
import { DATETIME_FORMAT, DATE_FORMAT } from "../constants/App";

export const formatDate = (date, outFormat = DATE_FORMAT, inFormat = null) => {
   if (!date) return null;
   let m = null;
   if (inFormat) {
      m = moment(date, inFormat);
   } else {
      m = moment(date);
   }
   return m.format(outFormat);
};

export const formatDateTime = (date) => {
   return formatDate(date, DATETIME_FORMAT);
};
