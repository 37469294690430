import { EventType } from "../../constants/Enums";

export const getEventTypeColor = (type) => {
   switch (type) {
      case EventType.SCADENZA:
         return "purple";
      case EventType.AVVISO_ACCERTAMENTO:
         return "red";
      case EventType.SCADENZA_BOLLETTA:
         return "yellow";
      case EventType.ALTRO:
         return "cyan";
      default:
         return "darkgray";
   }
};

export const eventIsDeletable = (type) => {
   switch (type) {
      case EventType.AVVISO_ACCERTAMENTO:
      case EventType.ALTRO:
         return true;
      default:
         return false;
   }
};
