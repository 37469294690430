import React, { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";

import IntlMessages from "../util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

import { useMutation } from "@apollo/client";
import { GQL_VERIFY_USER } from "../apollo/mutation/user";
import { injectIntl } from "react-intl";

const VerifyUser = () => {
   const history = useHistory();
   const { tokenId } = useParams();
   const [verifyUser] = useMutation(GQL_VERIFY_USER);
   const [loadingMutation, setLoadingMutation] = useState(true);
   const [success, setSuccess] = useState(false);

   useEffect(() => {
      verifyUser({ variables: { token: tokenId } })
         .then(({ data }) => {
            if (data && data.verifyUser.id) {
               setSuccess(true);
            }

            setLoadingMutation(false);
         })
         .catch((e) => {
            setLoadingMutation(false);
            setSuccess(false);
         });
   }, [tokenId]);

   if (!tokenId) {
      history.push("/");
      return null;
   }

   if (loadingMutation) {
      return <CircularProgress />;
   }

   const status = success ? "success" : "error";
   const title = success ? (
      <IntlMessages id="verify.verify_user_success_title" />
   ) : (
      <IntlMessages id="verify.verify_user_error_title" />
   );
   const subtitle = success ? (
      <IntlMessages id="verify.verify_user_success_message" />
   ) : (
      <IntlMessages id="verify.verify_user_error_message" />
   );

   return (
      <div className="gx-app-login-wrap">
         <Result
            key="1"
            status={status}
            title={<span className="color-blue">{title}</span>}
            subTitle={<span className="color-blue">{subtitle}</span>}
            extra={[
               <Link to="/" key="1">
                  <Button
                     size="large"
                     key="login"
                     className="bg-orange white uppercase border-0 gx-mb-0"
                  >
                     <IntlMessages id="login.signIn" />
                  </Button>
               </Link>,
            ]}
         />
      </div>
   );
};

export default injectIntl(VerifyUser);
