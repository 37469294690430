export const EventChannels = {
   EVENTS: "events",
};

export const EventNames = {
   EVENT_UPDATED: "events.updated",
};

export function isPusherConfigurated() {
   return process.env.REACT_APP_PUSHER_KEY && process.env.REACT_APP_PUSHER_CLUSTER;
}
