import { gql } from "@apollo/client";

export const GQL_QUIZ_UPSERT = gql`
   mutation upsertQuiz($input: UpsertQuizInput!) {
      upsertQuiz(input: $input) {
         id
         date
         name
         published
         created_at
         updated_at
      }
   }
`;

export const GQL_QUIZ_DELETE = gql`
   mutation deleteQuiz($id: ID!) {
      deleteQuiz(id: $id)
   }
`;

export const GQL_QUIZ_COMPILE = gql`
   mutation compileQuiz($input: CompileQuizQuestionsInput!) {
      compileQuiz(input: $input)
   }
`;
