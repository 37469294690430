import React from "react";
import { Radio, Input, Rate } from "antd";
import { QuizQuestionType } from "../../constants/Enums";

export const getFormItemContent = (question, intl) => {
   switch (question.type) {
      case QuizQuestionType.OPEN:
         return <Input.TextArea rows={5} maxLength={1000} showCount />;
      case QuizQuestionType.NUMERIC_RANGE_5:
         return <Rate count={5} />;
      case QuizQuestionType.BOOL:
      case QuizQuestionType.BOOL_YES_JUSTIFY:
      case QuizQuestionType.BOOL_NO_JUSTIFY:
         return (
            <Radio.Group>
               <Radio value={true}>{intl.formatMessage({ id: "common.yes" })}</Radio>
               <Radio value={false}>{intl.formatMessage({ id: "common.no" })}</Radio>
            </Radio.Group>
         );
      default:
         return null;
   }
};
