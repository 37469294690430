import React, { useEffect } from "react";
import { Badge, Popover } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GQL_EVENTS_IN_RANGE } from "../../../apollo/query/events";
import EventsList from "./list";
import { useIntl } from "react-intl";
import { get } from "lodash";
import moment from "moment";
import { EventType } from "../../../constants/Enums";
import Pusher from "pusher-js";
import { EventChannels, EventNames } from "../../../constants/EventChannels";

const EVENTS_NEXT_DAYS = 30;
/**
 * Non mostra gli eventi di tipo "ACCERTAMENTO" se sono basati sul campo date invece che reminder_at
 * @param {*} events
 */
function filterEvents(events) {
   return events.filter((event) => {
      if (event.type === EventType.AVVISO_ACCERTAMENTO && event.reminder_at) {
         return moment(event.reminder_at).isSame(moment(), "day");
      }
      return true;
   });
}

const EventsWidget = () => {
   const { data, error, loading, refetch } = useQuery(GQL_EVENTS_IN_RANGE, {
      variables: {
         input: {
            from: moment().startOf("day").format("YYYY-MM-DD"),
            to: moment()
               .endOf("day")
               .add(EVENTS_NEXT_DAYS, "days")
               .format("YYYY-MM-DD"),
         },
      },
   });
   const intl = useIntl();

   useEffect(() => {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
         cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      });

      const channel = pusher.subscribe(EventChannels.EVENTS);
      channel.bind(EventNames.EVENT_UPDATED, (data) => refetch && refetch());

      //return will be called when component unmounts
      return () => {
         pusher.unsubscribe(EventChannels.EVENTS);
         pusher.disconnect();
      };

      //eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (error) return null;

   const events = filterEvents(get(data, "eventsInRange", []));

   return (
      <div>
         <Popover
            className="gx-d-block gx-pointer gx-text-light"
            placement="bottomRight"
            content={
               <EventsList
                  events={events}
                  loading={loading}
                  days={EVENTS_NEXT_DAYS}
               />
            }
            trigger="click"
         >
            <Badge count={events.length} size="small" color="pink">
               <NotificationOutlined
                  style={{ fontSize: 18 }}
                  className="gx-text-light"
                  title={intl.formatMessage(
                     { id: "events.widget_next_x_days_p1" },
                     { days: EVENTS_NEXT_DAYS }
                  )}
               />
            </Badge>

            <i className="icon icon-chevron-down gx-fs-xxs" />
         </Popover>
      </div>
   );
};

export default EventsWidget;
