import { gql } from "@apollo/client";

export const GQL_QUIZ_LIST = gql`
   query quizList($orderBy: OrderByClause) {
      quizList(orderBy: $orderBy) {
         id
         date
         name
         answersCount
         published
         questions {
            id
            order
            description
            type
         }
      }
   }
`;

export const GQL_QUIZ_TO_COMPILE = gql`
   query quizToCompile {
      quizToCompile {
         id
         date
         name
         questions {
            id
            order
            description
            type
         }
      }
   }
`;

export const GQL_QUIZ_DETAIL = gql`
   query quiz($id: ID!) {
      quiz(id: $id) {
         id
         date
         name
         answersCount
         questions {
            id
            order
            description
            type
            answers {
               id
               user {
                  id
                  last_name
                  first_name
               }
               value
               comment
            }
         }
      }
   }
`;
