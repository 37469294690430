import { useMutation, useQuery } from "@apollo/client";
import { message, Select } from "antd";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { GQL_ADMIN_USER_SET_CURRENT_CUSTOMER } from "../../apollo/mutation/user";
import { GQL_ALL_CUSTOMERS } from "../../apollo/query/customer";
import { GQL_QUERY_ME } from "../../apollo/query/user";
import { useHistory } from "react-router-dom";
import { getAdminPath } from "../../util/router";

const CustomerSelectForm = ({
   me,
   onChange,
   onClear,
   onLoading,
   refreshPageAfterMutation,
   ...rest
}) => {
   const intl = useIntl();
   const history = useHistory();

   const { data, loading, error } = useQuery(GQL_ALL_CUSTOMERS);

   const [updateCurrentCustomer, { loading: loadingMutation }] = useMutation(
      GQL_ADMIN_USER_SET_CURRENT_CUSTOMER
   );

   useEffect(() => {
      if (onLoading) {
         onLoading(loadingMutation);
      }
   }, [loadingMutation]);

   const customers = get(data, "clientsAll", []);

   return (
      <Select
         status={error ? "error" : null}
         disabled={customers.length === 0}
         loading={loading}
         allowClear
         showSearch
         placeholder={intl.formatMessage({ id: "customers.select_customer" })}
         filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
         }
         defaultValue={get(me, "current_client.id", null)}
         onClear={() => {
            if (onClear) onClear();
         }}
         onChange={(value) => {
            updateCurrentCustomer({
               variables: {
                  client_id: value,
               },
               refetchQueries: [GQL_QUERY_ME],
               awaitRefetchQueries: true,
            })
               .then((response) => {
                  if (refreshPageAfterMutation === true) {
                     history.push(getAdminPath("workarea"));
                  }
               })
               .catch((error) => message.error(error.message));
         }}
         {...rest}
      >
         {customers.map((c, index) => {
            return (
               <Select.Option key={`customer-sel-${index}`} value={c.id}>
                  {c.company_name}
               </Select.Option>
            );
         })}
      </Select>
   );
};

const CustomerSelect = ({ onChange, onLoading, ...rest }) => {
   const { data } = useQuery(GQL_QUERY_ME);

   const me = get(data, "me", null);

   return (
      <CustomerSelectForm
         me={me}
         onChange={onChange}
         onLoading={onLoading}
         {...rest}
      />
   );
};

export default CustomerSelect;
