import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";

const BadResponse = ({ title, subtitle, redirectURL, status = '500', ...rest }) => {
   return (
      <Result
         {...rest}
         status={status}
         title={title}
         subTitle={subtitle}
         extra={
            <Link to={redirectURL}>
               <Button type="primary">
                  <IntlMessages id="404.home" />
               </Button>
            </Link>
         }
      />
   );
};

BadResponse.defaultProps = {
   redirectURL: "/",
};

export default BadResponse;
