import React, { useEffect } from "react";
import { Modal } from "antd";
import { formatDate } from "../../util/date";

const FeatureOverviewModal = ({ item, onClose, mutation, refetch }) => {
   useEffect(() => {
      mutation({
         variables: {
            ids: [item.id],
         },
      }).then(() => {
         refetch();
      });
   }, []);

   return (
      <Modal
         zIndex={90000}
         maskClosable={false}
         destroyOnClose={true}
         title={item.title}
         visible={true}
         onCancel={onClose}
         footer={<small>{formatDate(item.feature_date)}</small>}
      >
         <div
            style={{ maxHeight: 400, overflow: "auto" }}
            dangerouslySetInnerHTML={{ __html: item.description }}
         ></div>
      </Modal>
   );
};

export default FeatureOverviewModal;
