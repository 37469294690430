import { ADMIN_ROUTE_PREFIX } from "../constants/App";
import React from 'react';

export const getAdminPath = (relativePath, queryObject = null) => {
   let domain = ""; //relative
   if (window.location.origin !== process.env.REACT_APP_BASE_URL) {
      domain = process.env.REACT_APP_BASE_URL; //absolute
   }

   const queryString = queryObject
      ? `?${new URLSearchParams(queryObject).toString()}`
      : "";

   const url = `${domain}/${ADMIN_ROUTE_PREFIX}${
      relativePath ? `/${relativePath}` : ""
   }${queryString}`;

   return url.replace(/[\/]+/g, "/");
};

export const pushNextOrFallback = (history, fallback) => {
   if (history.location.search) {
      const qParams = new URLSearchParams(history.location.search);
      const nextURL = qParams.get("next");

      if (nextURL) {
         history.push(nextURL);
         return;
      }
   }

   if (fallback) {
      history.push(fallback);
   } else {
      history.push("/");
   }
};

export const requestByEmailHref = (text = "", tracker = null) => {
   if (tracker) {
      tracker();
   }

   const senderMail = process.env.REACT_APP_MAIL;
   return `mailto:${senderMail}?subject=${encodeURIComponent(text)}`;
};

/**
 * vedi https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
 * https://www.codemzy.com/blog/fix-chunkloaderror-react
 * note:
 * If you need to do multiple module imports on a single route/page and one is successful and the other isn't, the successful one could keep resetting the sessionStorage. It would create that endless refresh loop we talked about earlier.
 * In that situation, I would pass a value as a name to the lazyRetry function to identify the module.
 * @param {*} componentImport 
 * @returns 
 */
 export const lazyWithRetry = (componentImport, name = '') => {
   return React.lazy(async () => {

      const key = `page${name}-has-been-force-refreshed`;

      const pageHasAlreadyBeenForceRefreshed = JSON.parse(
         window.localStorage.getItem(
            key
         ) || 'false'
      );

      try {
         const component = await componentImport();

         window.localStorage.setItem(
            key,
            'false'
         );

         return component;
      } catch (error) {
         if (!pageHasAlreadyBeenForceRefreshed) {
            // Assuming that the user is not on the latest version of the application.
            // Let's refresh the page immediately.
            window.localStorage.setItem(
               key,
               'true'
            );
            return window.location.reload();
         }

         // The page has already been reloaded
         // Assuming that user is already using the latest version of the application.
         // Let's let the application crash and raise the error.
         throw error;
      }
   });
}
