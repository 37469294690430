import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import UserProfile from "../../containers/Topbar/UserProfile";
import EventsWidget from "../../components/Events/Widget";
import {
   NAV_STYLE_DRAWER,
   NAV_STYLE_FIXED,
   NAV_STYLE_MINI_SIDEBAR,
   TAB_SIZE,
} from "../../constants/ThemeSetting";
import { layoutSettingsVar, globalSettingsVar } from "../../apollo/local/cache";
import { useQuery, useReactiveVar } from "@apollo/client";
import VerificationsWidget from "../../components/Dossier/VerificationsWidget";
import { GQL_LOCAL_ME } from "../../apollo/query/user";
import { Roles } from "../../constants/Enums";
import FeatureOverviewListWidget from "../../components/FeatureOverview/widget";

const { Header } = Layout;

const Topbar = () => {
   var { navStyle } = useReactiveVar(layoutSettingsVar);
   var { width, sideBarCollapsed } = useReactiveVar(globalSettingsVar);
   const logoClassName = sideBarCollapsed ? "gx-d-lg-block" : "gx-d-lg-none";

   const { data } = useQuery(GQL_LOCAL_ME);

   return (
      <Header style={{ padding: 0 }}>
         {navStyle === NAV_STYLE_DRAWER ||
         ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
            width < TAB_SIZE) ? (
            <div className="gx-linebar gx-mr-3">
               <i
                  className="gx-icon-btn icon icon-menu"
                  onClick={(e) => {
                     e.stopPropagation();
                     globalSettingsVar({
                        ...globalSettingsVar(),
                        navCollapsed: true,
                     });
                  }}
               />
            </div>
         ) : null}

         <Link to="/" className={`gx-d-block ${logoClassName} gx-pointer`}>
            <img alt="" src="/assets/images/logo-small.png" height={34} />
         </Link>

         <ul className="gx-header-notifications gx-ml-auto">
            <li className="gx-notify gx-mr-3">
               <FeatureOverviewListWidget />
            </li>

            {[Roles.SUPERADMIN, Roles.ADMIN].includes(data?.me?.user_type) && (
               <>
                  <li className="gx-notify gx-mr-3">
                     <EventsWidget />
                  </li>
                  <li className="gx-notify gx-mr-3">
                     <VerificationsWidget />
                  </li>
               </>
            )}
            <li className="gx-notify">
               <UserProfile />
            </li>
         </ul>
      </Header>
   );
};

export default Topbar;
