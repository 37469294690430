import { CalendarOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Col, List, Row, Skeleton, Spin } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { getAdminPath } from "../../../util/router";
import { useIntl } from "react-intl";
import { VERIFICATION_NEXT_DAYS } from ".";
import { formatDate } from "../../../util/date";
import moment from "moment";

const getDateDiff = (date, intl) => {
   const m = moment(date).startOf("day");
   const now = moment().startOf("day");
   const diff = m.diff(now, "days");

   if (diff > 0) return ` (${diff}${intl.formatMessage({ id: "common.day_abbr" })})`;

   return "";
};

const VerificationsList = ({ events, loading }) => {
   const intl = useIntl();
   if (loading)
      return (
         <div className="gx-p-2 gx-text-center">
            <Spin className="gx-p-0 gx-m-0" />
         </div>
      );

   if (!events || events.length === 0) {
      return (
         <div className="gx-p-2 gx-text-center">
            <small>
               {intl.formatMessage(
                  { id: "verifications.dossier_verification_next_days_empty" },
                  { days: VERIFICATION_NEXT_DAYS }
               )}
            </small>
         </div>
      );
   }

   return (
      <List
         className="demo-loadmore-list"
         itemLayout="horizontal"
         dataSource={events}
         header={
            <Row>
               <Col xs={20}>
                  <span className="gx-font-weight-semi-bold">
                     {intl.formatMessage(
                        { id: "verifications.dossier_verification_next_days" },
                        { days: VERIFICATION_NEXT_DAYS }
                     )}
                  </span>
               </Col>
               <Col xs={4} className="gx-text-right">
                  <Link to={getAdminPath("dashboard")}>
                     <CalendarOutlined
                        className="gx-text-underline gx-text-info"
                        title={intl.formatMessage({ id: "common.deadlines" })}
                     />
                  </Link>
               </Col>
            </Row>
         }
         style={{
            width: 300,
            maxHeight: 400,
            overflowY: "auto",
            overflowX: "hidden",
         }}
         rowKey="id"
         renderItem={(event) => (
            <List.Item
               actions={[
                  <Link
                     to={getAdminPath(`workarea/dossier/update/${event.model_id}`)}
                  >
                     <CaretRightOutlined />
                  </Link>,
               ]}
            >
               <Skeleton title={false} loading={false} active>
                  <List.Item.Meta
                     title={event.title}
                     description={
                        <small>
                           {formatDate(event.reminder_at)}
                           {getDateDiff(event.reminder_at, intl)}
                        </small>
                     }
                  />
               </Skeleton>
            </List.Item>
         )}
      />
   );
};

export default VerificationsList;
