import React from "react";
import { useHistory } from "react-router";
import { Avatar, Popover, Skeleton } from "antd";
import IntlMessages from "../../util/IntlMessages";
import { Link } from "react-router-dom";
import { GQL_QUERY_ME } from "../../apollo/query/user";
import { useMutation, useQuery } from "@apollo/client";
import { localStorageClass } from "../../apollo/local/storage";
import { getInitials } from "../../util/string";
import { SECONDARY_COLOR } from "../../constants/ThemeSetting";
import { GQL_LOGOUT } from "../../apollo/mutation/user";
import { get, startCase, toLower } from "lodash";
import { getAdminPath } from "../../util/router";

const getAdminOptions = (history, logoutHandler) => {
   return (
      <ul className="gx-user-popover">
         <li>
            <Link className="gx-d-block" to={getAdminPath("profile")}>
               <IntlMessages id="profile.update" />
            </Link>
         </li>
         <li>
            <a
               className="gx-d-block gx-text-danger"
               onClick={(e) => {
                  e.stopPropagation();
                  logoutHandler();
                  localStorageClass.logout();
                  history.push("/");
               }}
            >
               <IntlMessages id="sidebar.logout" />
            </a>
         </li>
      </ul>
   );
};

const UserProfile = () => {
   const [logout] = useMutation(GQL_LOGOUT);
   const history = useHistory();
   //TODO riorganizzare query startup, no cache per evitare duplicazione chiamate
   const { data, loading, error } = useQuery(GQL_QUERY_ME, {
      fetchPolicy: "no-cache",
   });

   if (loading) return <Skeleton.Avatar />;

   if (error || !data) return <div></div>;

   if (!data || !data.me) {
      localStorageClass.logout();
      history.push("/");
      return null;
   }

   const { me } = data;
   const f = get(me, "first_name", "");
   const l = get(me, "last_name", "");

   const initials = getInitials(`${f} ${l}`);

   return (
      <div>
         <Popover
            className="gx-d-block"
            placement="bottomRight"
            content={getAdminOptions(history, () => logout())}
            trigger="click"
         >
            <span className="gx-avatar-name gx-mr-1">
               <small>{startCase(toLower(me.full_name))}</small>
            </span>

            <Avatar
               style={{
                  backgroundColor: SECONDARY_COLOR,
               }}
               className={"gx-size-40 gx-pointer gx-mr-1"}
               alt=""
            >
               {initials.toUpperCase()}
            </Avatar>

            <i className="icon icon-chevron-down gx-fs-xxs" />
         </Popover>
      </div>
   );
};

export default UserProfile;
