import React from "react";
import { Badge, Popover } from "antd";
import { BookOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { get } from "lodash";
import { GQL_FEATURES_OVERVIEW_USER } from "../../apollo/query/feature-overview";
import FeatureOverviewList from "./list";

function countFeatureNotViewed(features) {
   return features.filter((feature) => !feature.overview_user).length;
}

const FeatureOverviewListWidget = () => {
   const { data, error, refetch } = useQuery(GQL_FEATURES_OVERVIEW_USER);
   const intl = useIntl();

   const features = get(data, "featuresOverview", []);
   if (error || features.length === 0) return null;

   return (
      <div>
         <Popover
            className="gx-d-block gx-pointer gx-text-light"
            placement="bottomRight"
            content={
               <FeatureOverviewList
                  intl={intl}
                  features={features}
                  refetch={refetch}
               />
            }
            trigger="click"
         >
            <Badge count={countFeatureNotViewed(features)} size="small" color="pink">
               <BookOutlined
                  style={{ fontSize: 18 }}
                  className="gx-text-light"
                  title={intl.formatMessage({ id: "common.features_overview" })}
               />
            </Badge>

            <i className="icon icon-chevron-down gx-fs-xxs" />
         </Popover>
      </div>
   );
};

export default FeatureOverviewListWidget;
