export const Roles = {
   SUPERADMIN: "SUPERADMIN",
   ADMIN: "ADMIN",
   EDITOR: "EDITOR",
   CLIENT: "CLIENT",
};

export const ACLSubject = {
   DOSSIER: "dossier",
   CLIENT: "client",
   USER: "user",
   ASSET: "asset",
   CLIENT_ASSET: "client_asset",
   TAX_ACCOUNT: "tax_account",
   TAX_PROVIDER: "tax_provider",
   BILL: "bill",
   SETTING: "setting",
   TEMPLATE: "template",
   CHARITY: "charity",
   DATA_ANALYSIS: "data_analysis",
   REPORT: "report",
   ESTIMATE: "estimate",
   CRM_CONTACT: "crm_contact",
};

export const ResponseErrorType = {
   GRAPHQL_ERROR: 1,
   NETWORK_ERROR: 2,
};

export const AssetType = {
   DOCUMENTO: "DOCUMENTO",
   BOLLETTA: "BOLLETTA",
   ISTANZA: "ISTANZA",
   CONTRATTO: "CONTRATTO",
   LOGO: "LOGO",
   TEMPLATE: "TEMPLATE",
   CLIENT: "CLIENT",
   TAX_ACCOUNT: "TAX_ACCOUNT",
   CHARITY: "CHARITY",
   PRIVATE_TAX_PROVIDER: "PRIVATE_TAX_PROVIDER",
};

export const ClientCommissionType = {
   ND: "ND",
   PERCENTUALE: "PERCENTUALE",
   FISSO: "FISSO",
};

export const DossierStatus = {
   BOZZA: "BOZZA",
   ACCOLTA: "ACCOLTA",
   RIFIUTATA: "RIFIUTATA",
   DOCUMENTAZIONE_MANCANTE: "DOCUMENTAZIONE_MANCANTE",
   ANNULLATA: "ANNULLATA",
   INVIATA: "INVIATA",
   PRONTA_PER_INVIO: "PRONTA_PER_INVIO",
   IN_CODA_PER_INVIO: "IN_CODA_PER_INVIO",
   INVIATA_E_ACCETTATA: "INVIATA_E_ACCETTATA",
   SOLLECITATA: "SOLLECITATA",
   PRESA_IN_CARICO_DALL_ENTE: "PRESA_IN_CARICO_DALL_ENTE",
};

export const DossierBillType = {
   ORIGINALE: "ORIGINALE",
   RETTIFICA: "RETTIFICA",
   COMPARAZIONE: "COMPARAZIONE",
   PROVVIGIONE: "PROVVIGIONE",
};

export const TemplateOwner = {
   OWNER: "OWNER", //Tecnodata
   CLIENT: "CLIENT",
};

export const DossierSendingMode = {
   SINGLE: "single", //Invio singola pratica
   MULTIPLE: "multiple", //Invio pratica multipla
};

export const QueueType = {
   IMAGE: "IMAGE",
   DOSSIER_SEND: "DOSSIER_SEND",
};

export const CustomerNotificationReminder = {
   DAY_ITSELF: 0,
   DAY_BEFORE: 1,
   DAYS_5_BEFORE: 5,
   DAYS_10_BEFORE: 10,
};

export const DossierMessageType = {
   COMUNICATION: "COMUNICATION",
   INTEGRATION: "INTEGRATION",
   REMINDER: "REMINDER",
   PROVIDER_REQUEST: "PROVIDER_REQUEST",
   CLIENT_REQUEST: "CLIENT_REQUEST",
};

export const DossierMessageMode = {
   IN_ENTRATA: 0,
   IN_USCITA: 1,
};

export const EventType = {
   SCADENZARIO: "SCADENZARIO",
   AVVISO_ACCERTAMENTO: "AVVISO_ACCERTAMENTO",
   SCADENZA_BOLLETTA: "SCADENZA_BOLLETTA",
   ALTRO: "ALTRO",
};

export const TemplateType = {
   ISTANZA: "ISTANZA",
   ESTIMATE_REQUEST: "ESTIMATE_REQUEST",
};

export const EstimateRequestStatusType = {
   DRAFT: "DRAFT",
   SENDING_QUEUE: "SENDING_QUEUE",
   SENT: "SENT",
   ERROR: "ERROR",
};

export const CRMEventType = {
   GENERIC: "GENERIC",
   CONTACT: "CONTACT",
   NEGOTIATION: "NEGOTIATION",
};

export const CRMContactLinkType = {
   PHONE_NUMBER: "PHONE_NUMBER",
   MOBILE: "MOBILE",
   FAX: "FAX",
   WEB: "WEB",
   EMAIL: "EMAIL",
   PEC: "PEC",
   FACEBOOK: "FACEBOOK",
   TWITTER: "TWITTER",
   INSTAGRAM: "INSTAGRAM",
   LINKEDIN: "LINKEDIN",
   WHATSAPP: "WHATSAPP",
};

export const CRMNegotiationStatusType = {
   INVIO_IN_CORSO: "INVIO_IN_CORSO",
   CONTATTATO: "CONTATTATO",
   QUALIFICATO: "QUALIFICATO",
   PROPOSTA_FATTA: "PROPOSTA_FATTA",
   IN_VALUTAZIONE: "IN_VALUTAZIONE",
   AGGIUDICATO: "AGGIUDICATO",
   RESPINTO: "RESPINTO",
};

export const CRMAttachmentType = {
   NEGOTIATION_MESSAGE: "NEGOTIATION_MESSAGE",
   GENERIC: "GENERIC",
};

export const CRMNegotiationMessageType = {
   EMAIL: "EMAIL",
   EMAIL_USER: "EMAIL_USER",
   PEC: "PEC",
   PHONE_OR_MOBILE: "PHONE_OR_MOBILE",
};

export const DirectionType = {
   IN: "IN",
   OUT: "OUT",
};

export const QuizQuestionType = {
   OPEN: "OPEN",
   NUMERIC_RANGE_5: "NUMERIC_RANGE_5",
   BOOL: "BOOL",
   BOOL_YES_JUSTIFY: "BOOL_YES_JUSTIFY",
   BOOL_NO_JUSTIFY: "BOOL_NO_JUSTIFY",
};
