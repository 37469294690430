import React, { useEffect } from "react";
import { Badge, Popover } from "antd";
import { AlertOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GQL_EVENTS_VERIFICATIONS_NEXT_DAYS } from "../../../apollo/query/events";
import VerificationsList from "./list";
import { useIntl } from "react-intl";
import { get } from "lodash";
import { EventChannels, EventNames } from "../../../constants/EventChannels";
import Pusher from "pusher-js";

export const VERIFICATION_NEXT_DAYS = 30;

const VerificationsWidget = () => {
   const { data, error, loading, refetch } = useQuery(
      GQL_EVENTS_VERIFICATIONS_NEXT_DAYS,
      {
         variables: {
            input: {
               days: VERIFICATION_NEXT_DAYS,
            },
         },
      }
   );
   const intl = useIntl();

   useEffect(() => {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
         cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      });

      const channel = pusher.subscribe(EventChannels.EVENTS);
      channel.bind(EventNames.EVENT_UPDATED, (data) => refetch && refetch());

      //return will be called when component unmounts
      return () => {
         pusher.unsubscribe(EventChannels.EVENTS);
         pusher.disconnect();
      };

      //eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (error) return null;

   const events = get(data, "verificationsInNextDays", []);

   return (
      <div>
         <Popover
            className="gx-d-block gx-pointer gx-text-light"
            placement="bottomRight"
            content={<VerificationsList events={events} loading={loading} />}
            trigger="click"
         >
            <Badge count={events.length} size="small" color="pink">
               <AlertOutlined
                  style={{ fontSize: 18 }}
                  className="gx-text-light"
                  title={intl.formatMessage(
                     { id: "verifications.dossier_verification_next_days" },
                     { days: VERIFICATION_NEXT_DAYS }
                  )}
               />
            </Badge>

            <i className="icon icon-chevron-down gx-fs-xxs" />
         </Popover>
      </div>
   );
};

export default VerificationsWidget;
