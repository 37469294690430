import React, { memo, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import AdminApp from "./AdminApp";
import SignIn from "../SignIn";
import ForgetPassword from "../ForgetPassword";
import ResetPassword from "../ResetPassword";

import {
   LAYOUT_TYPE_BOXED,
   LAYOUT_TYPE_FRAMED,
   LAYOUT_TYPE_FULL,
   NAV_STYLE_ABOVE_HEADER,
   NAV_STYLE_BELOW_HEADER,
   NAV_STYLE_DARK_HORIZONTAL,
   NAV_STYLE_DEFAULT_HORIZONTAL,
   NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
   THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";

import { useReactiveVar } from "@apollo/client";
import { layoutSettingsVar } from "../../apollo/local/cache";
import { localStorageClass } from "../../apollo/local/storage";
import NotFound from "../NotFound";
import VerifyUser from "../VerifyUser";
import UnderMaintenance from "../UnderMaintenance";

const RestrictedAdminRoute = ({
   component: Component,
   location,
   localStorageClass,
   ...rest
}) => {
   return (
      <Route
         {...rest}
         render={(props) =>
            localStorageClass.isLogged() ? (
               <Component {...props} />
            ) : (
               <Redirect
                  to={{
                     pathname: "/",
                     state: { from: location },
                  }}
               />
            )
         }
      />
   );
};

const App = ({ ability }) => {
   let { locale, themeType, navStyle, layoutType, themeColor } =
      useReactiveVar(layoutSettingsVar);

   const location = useLocation();

   const setLayoutType = (layoutType) => {
      if (layoutType === LAYOUT_TYPE_FULL) {
         document.body.classList.remove("boxed-layout");
         document.body.classList.remove("framed-layout");
         document.body.classList.add("full-layout");
      } else if (layoutType === LAYOUT_TYPE_BOXED) {
         document.body.classList.remove("full-layout");
         document.body.classList.remove("framed-layout");
         document.body.classList.add("boxed-layout");
      } else if (layoutType === LAYOUT_TYPE_FRAMED) {
         document.body.classList.remove("boxed-layout");
         document.body.classList.remove("full-layout");
         document.body.classList.add("framed-layout");
      }
   };

   const setNavStyle = (navStyle) => {
      if (
         navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
         navStyle === NAV_STYLE_DARK_HORIZONTAL ||
         navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
         navStyle === NAV_STYLE_ABOVE_HEADER ||
         navStyle === NAV_STYLE_BELOW_HEADER
      ) {
         document.body.classList.add("full-scroll");
         document.body.classList.add("horizontal-layout");
      } else {
         document.body.classList.remove("full-scroll");
         document.body.classList.remove("horizontal-layout");
      }
   };

   useEffect(() => {
      // duplica importazione dei css può essere utile per frontend
      // let link = document.createElement("link");
      // link.type = "text/css";
      // link.rel = "stylesheet";
      // link.href = `/css/${themeColor}.css`; //This line is changed, this comment is for explaination purpose.
      // link.className = "gx-style";
      // document.body.appendChild(link);
   }, [themeColor]);

   useEffect(() => {
      setLayoutType(layoutType);
      setNavStyle(navStyle);
   });

   useEffect(() => {
      if (themeType === THEME_TYPE_DARK) {
         document.body.classList.add("dark-theme");
         let link = document.createElement("link");
         link.type = "text/css";
         link.rel = "stylesheet";
         link.href = "/css/dark_theme.css";
         link.className = "style_dark_theme";
         document.body.appendChild(link);
      }
   }, [themeType]);

   const currentAppLocale = AppLocale[locale.locale];

   return (
      <ConfigProvider locale={currentAppLocale.antd}>
         <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
         >
            <Switch>
               <Route path="/under-maintenance" component={UnderMaintenance} />
               <Route key="signin" exact path={"/"} component={SignIn} />
               {/* <Route key="signup" exact path="signup" component={SignUp} />, */}
               <Route
                  key="forget-password"
                  exact
                  path="/forget-password"
                  component={ForgetPassword}
               />
               <Route
                  key="reset-password"
                  exact
                  path={"/reset-password"}
                  component={ResetPassword}
               />
               ,
               <Route
                  key="verify"
                  exact
                  path={["/verify/:tokenId", "/verify/:tokenId/:redirectToAdmin"]}
                  component={VerifyUser}
               />
               <RestrictedAdminRoute
                  key="restricted-routes"
                  path="/"
                  localStorageClass={localStorageClass}
                  location={location}
                  ability={ability}
                  component={AdminApp}
               />
               <Route path="*">
                  <NotFound redirectToURL={"/"} />
               </Route>
            </Switch>
         </IntlProvider>
      </ConfigProvider>
   );
};

export default memo(App);
