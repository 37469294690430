import React from "react";
import { Link } from "react-router-dom";
import {
   NAV_STYLE_DRAWER,
   NAV_STYLE_FIXED,
   NAV_STYLE_MINI_SIDEBAR,
   TAB_SIZE,
   THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { getAdminPath } from "../../util/router";
import { layoutSettingsVar, globalSettingsVar } from "../../apollo/local/cache";
import { useReactiveVar } from "@apollo/client";

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
   //
   var { width } = useReactiveVar(globalSettingsVar);
   var { navStyle, themeType } = useReactiveVar(layoutSettingsVar);
   //

   if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
   }

   const logoStyle = {
      userSelect: "none",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      OUserSelect: "none",
      khtmlUserSelect: "none",
      height: 44,
   };

   return (
      <div className="gx-layout-sider-header">
         {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
            <div className="gx-linebar">
               <i
                  className={`gx-icon-btn icon icon-${
                     !sidebarCollapsed ? "menu-unfold" : "menu-fold"
                  } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
                  onClick={(e) => {
                     e.stopPropagation();
                     setSidebarCollapsed(!sidebarCollapsed);
                  }}
               />
            </div>
         ) : null}
         <Link to={getAdminPath("dashboard")} className="gx-site-logo">
            <img alt="" src="/assets/images/logo-small.png" style={logoStyle} />
         </Link>
      </div>
   );
};

export default SidebarLogo;
