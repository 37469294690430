import React from "react";


//Commentato perchè su caricamento compare per qualche istante ogni tanto
const Error403 = () => (
   <div className="gx-page-error-container">
      {/* <div className="gx-page-error-content">
         <div className="gx-error-code">403</div>
         <h2 className="gx-text-center">
            <IntlMessages id="403.message" />
         </h2>

         <p className="gx-text-center">
            <Link className="gx-btn gx-btn-primary" to="/">
               <IntlMessages id="common.home" />
            </Link>
         </p>
      </div> */}
   </div>
);

export default Error403;
