import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAdminPath } from "../../util/router";
import {
   faDownload,
   faEuroSign,
   faGauge,
   faStamp,
   faStoreAlt,
} from "@fortawesome/free-solid-svg-icons";

const ICON_STYLE = { width: 20 };

const SidebarCustomerContent = ({ client }) => {
   if (!client) return null;

   return (
      <>
         <Menu.Item key={getAdminPath("workarea")}>
            <Link to={getAdminPath("workarea")}>
               <span className="icon" style={ICON_STYLE}>
                  <FontAwesomeIcon icon={faGauge} />
               </span>
               <span>
                  <IntlMessages id="sidebar.dashboard" />
               </span>
            </Link>
         </Menu.Item>
         <Menu.Item key={getAdminPath(`workarea/dossier`)}>
            <Link to={getAdminPath(`workarea/dossier`)}>
               <span className="icon" style={ICON_STYLE}>
                  <FontAwesomeIcon icon={faStamp} />
               </span>
               <span>
                  <IntlMessages id="common.dossiers" />
               </span>
            </Link>
         </Menu.Item>
         <Menu.Item key={getAdminPath(`workarea/tari`)}>
            <Link to={getAdminPath(`workarea/tari`)}>
               <span className="icon" style={ICON_STYLE}>
                  <FontAwesomeIcon icon={faEuroSign} />
               </span>
               <span>
                  <IntlMessages id="common.tari" />
               </span>
            </Link>
         </Menu.Item>

         <Menu.Item key={getAdminPath(`customers/update/${client.id}#2`)}>
            <Link to={getAdminPath(`customers/update/${client.id}#2`)}>
               <span className="icon" style={ICON_STYLE}>
                  <FontAwesomeIcon icon={faStoreAlt} />
               </span>
               <span>
                  <IntlMessages id="tax_accounts.tax_accounts_stores" />
               </span>
            </Link>
         </Menu.Item>

         <Menu.Item key={getAdminPath(`workarea/assets`)}>
            <Link to={getAdminPath(`workarea/assets`)}>
               <span className="icon" style={ICON_STYLE}>
                  <FontAwesomeIcon icon={faDownload} />
               </span>
               <span>
                  <IntlMessages id="sidebar.customer_assets" />
               </span>
            </Link>
         </Menu.Item>
      </>
   );
};

export default SidebarCustomerContent;
