import React, { useEffect, useState } from "react";
import { Button, Input, Form, Result, message, Row, Col } from "antd";
import { Link } from "react-router-dom";

import IntlMessages from "../util/IntlMessages";

import { useMutation } from "@apollo/client";
import { GQL_FORGOT_PASSWORD } from "../apollo/mutation/user";
import { injectIntl } from "react-intl";
import Header from "./Header";

const ForgetPassword = ({ intl }) => {
   const [success, setSuccess] = useState(false);
   const [loadingMutation, setLoadingMutation] = useState(false);
   const [forgotPassword] = useMutation(GQL_FORGOT_PASSWORD);

   const onFinish = (values) => {
      setLoadingMutation(true);

      /* The url used to reset the password. Use the __EMAIL__ and __TOKEN__ placeholders to inject the reset
         password email and token. e.g; https://my-front-end.com?reset-password?email=__EMAIL__&token=__TOKEN__ */
      const params = {
         email: values.email.toLowerCase(),
         reset_password_url: {
            url: `${process.env.REACT_APP_BASE_URL}/reset-password?email=__EMAIL__&token=__TOKEN__`,
         },
      };

      forgotPassword({ variables: { input: params } })
         .then(({ data }) => {
            setLoadingMutation(false);

            if (
               data &&
               data.forgotPassword &&
               data.forgotPassword.status === "EMAIL_SENT"
            ) {
               setSuccess(true);
            } else {
               message.error(
                  intl.formatMessage({
                     id: "login.forgot_password_error_message",
                  })
               );
            }
         })
         .catch((e) => {
            setLoadingMutation(false);
            message.error(
               intl.formatMessage({ id: "login.forgot_password_error_message" })
            );
         });
   };

   var inputRef = React.createRef();
   useEffect(() => {
      if (inputRef && inputRef.current) {
         inputRef.current.focus();
      }
   }, [inputRef]);

   return (
      <div className="gx-container-fluid bg-blue mh-100vh form-login-container">
         <div className="gx-container frontend-container">
            <div>
               <Header />

               {!success && (
                  <Row>
                     <Col
                        xl={{ span: 8, offset: 8 }}
                        lg={{ span: 12, offset: 6 }}
                        md={{ span: 18, offset: 3 }}
                        sm={{ span: 24, offset: 0 }}
                     >
                        <div
                           className="gx-w-100 gx-text-left mb-100"
                           id="login-form"
                        >
                           <h3 className="gx-mb-4">
                              <IntlMessages id="login.forgot_password1" />?
                           </h3>
                           <Form
                              layout="vertical"
                              name="basic"
                              onFinish={onFinish}
                              autoComplete="off"
                              className="gx-signin-form gx-form-row0"
                           >
                              <Form.Item
                                 required={false}
                                 label={<IntlMessages id="common.email" />}
                                 rules={[
                                    {
                                       required: true,
                                       message: intl.formatMessage({
                                          id: "validation.required",
                                       }),
                                    },
                                 ]}
                                 name="email"
                              >
                                 <Input
                                    placeholder={intl.formatMessage({
                                       id: "common.email",
                                    })}
                                    type="text"
                                    ref={inputRef}
                                 />
                              </Form.Item>

                              <Form.Item>
                                 <Button
                                    type="primary"
                                    className="gx-mb-3 gx-bg-secondary btn-f gx-w-100"
                                    htmlType="submit"
                                    name="buttonSubmit"
                                    loading={loadingMutation}
                                 >
                                    <strong>
                                       {intl
                                          .formatMessage({
                                             id: "common.reset_password",
                                          })
                                          .toUpperCase()}
                                    </strong>
                                 </Button>
                              </Form.Item>

                              <div className="gx-mb-2">
                                 <span>
                                    <IntlMessages id="common.or" />
                                 </span>
                                 &nbsp;
                                 <Link to="/">
                                    <u>
                                       <IntlMessages id="login.signIn" />
                                    </u>
                                 </Link>
                              </div>
                           </Form>
                        </div>
                     </Col>
                  </Row>
               )}

               {success && (
                  <div className="gx-app-login-wrap2">
                     <Result
                        key="1"
                        status="success"
                        title={
                           <h1>
                              <IntlMessages id="login.forgot_password_success_title" />
                           </h1>
                        }
                        subTitle={
                           <h4>
                              <IntlMessages id="login.forgot_password_success_message" />
                           </h4>
                        }
                        extra={[
                           <Link to="/">
                              <Button
                                 key="login"
                                 className="gx-mt-3 gx-mb-3 gx-bg-secondary btn-f gx-text-white"
                              >
                                 {intl
                                    .formatMessage({
                                       id: "common.login",
                                    })
                                    .toUpperCase()}
                              </Button>
                           </Link>,
                        ]}
                     />
                  </div>
               )}
            </div>
         </div>
      </div>
   );
};

export default injectIntl(ForgetPassword);
