import { Row, Col, List } from "antd";
import React, { useState } from "react";
import { formatDate } from "../../util/date";
import { useMutation } from "@apollo/client";
import { GQL_FEATURE_OVERVIEW_VIEWS } from "../../apollo/mutation/feature-overview";
import FeatureOverviewModal from "./modal";

const FeatureOverviewList = ({ intl, features, refetch }) => {
   const [markAsRead] = useMutation(GQL_FEATURE_OVERVIEW_VIEWS);
   const [showDetailModal, setShowDetailModal] = useState(null);

   return (
      <>
         <Row>
            <Col span={24} className="gx-text-right" style={{ paddingRight: 20 }}>
               <small
                  className="gx-text-underline gx-pointer"
                  onClick={() => {
                     markAsRead({
                        variables: {
                           ids: features
                              .filter((f) => f.overview_user === null)
                              .map((f) => f.id),
                        },
                     }).then(() => {
                        refetch();
                     });
                  }}
               >
                  {intl.formatMessage({ id: "features_overview.all_read" })}
               </small>
            </Col>
         </Row>
         <Row>
            <Col span={24} className="gx-pt-2">
               <List
                  zIndex={8999}
                  style={{ maxHeight: 300, overflow: "auto" }}
                  itemLayout="horizontal"
                  dataSource={features}
                  renderItem={(feature) => (
                     <List.Item
                        className="gx-pointer"
                        style={{
                           width: 300,
                           paddingLeft: 5,
                           paddingRight: 5,
                           backgroundColor: !feature.overview_user
                              ? "#add8e633"
                              : "transparent",
                        }}
                        onClick={() => {
                           setShowDetailModal(feature);
                        }}
                     >
                        <div><small>{feature.title}</small></div>
                        <div>
                           <small>{formatDate(feature.feature_date)}</small>
                        </div>
                     </List.Item>
                  )}
               />
            </Col>
         </Row>

         {showDetailModal && (
            <FeatureOverviewModal
               item={showDetailModal}
               onClose={() => setShowDetailModal(null)}
               mutation={markAsRead}
               refetch={refetch}
            />
         )}
      </>
   );
};

export default FeatureOverviewList;
