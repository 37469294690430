import { gql } from "@apollo/client";

export const GQL_EVENTS = gql`
   query events($input: EventsFilterInput!) {
      events(input: $input) {
         id
         date
         title
         description
         type
         comune {
            id
            description
         }
         client {
            id
            company_name
         }
         user {
            id
            full_name
         }
         model_id
         reminder_at
      }
   }
`;

export const GQL_EVENTS_FOR_DOSSIER = gql`
   query eventsForDossier($dossier_id: ID!) {
      eventsForDossier(dossier_id: $dossier_id) {
         id
         date
         title
         description
         reminder_at
      }
   }
`;

export const GQL_EVENTS_IN_RANGE = gql`
   query eventsInRange($input: DateRange!) {
      eventsInRange(input: $input) {
         id
         date
         title
         description
         type
         comune {
            id
            description
         }
         model_id
         reminder_at
      }
   }
`;

export const GQL_EVENTS_VERIFICATIONS_NEXT_DAYS = gql`
   query verificationsInNextDays($input: EventNexDaysInput!) {
      verificationsInNextDays(input: $input) {
         id
         date
         title
         description
         type
         comune {
            id
            description
         }
         model_id
         reminder_at
      }
   }
`;
