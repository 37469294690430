export const getInitials = (str, maxChars = null, uppercase = true) => {
   const inits = str
      .split(" ")
      .map((n) => {
         if (!n[0]) return "";
         return uppercase ? `${n[0].toUpperCase()}` : `${n[0]}`;
      })
      .join("");

   if (maxChars) {
      return inits.slice(0, maxChars);
   }

   return inits;
};

export const formatPercent = (value, digit = 2) => {
   const formatter = new Intl.NumberFormat("it-IT", {
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
   });
   return formatter.format(value) + `%`;
};

export const formatCurrency = (number, digits = 0, currency = "EUR") => {
   return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
   }).format(number);
};

export const generateRandomPassword = (length) => {
   const chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
   var password = "";
   for (var i = 0; i < length; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
   }

   return password;
};

export const stringToColor = function (str) {
   let hash = 0;
   for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
   }
   let colour = "#";
   for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
   }
   return colour;
};
