import React from "react";
import { Route, Switch } from "react-router-dom";
import Admin from "./admin/index";
import NotFound from "../containers/NotFound";
import { getAdminPath } from "../util/router";

const AdminRoutes = ({ ability }) => {
   return (
      <div className="gx-main-content-wrapper">
         <Switch>
            <Route path={getAdminPath()}>
               <Admin ability={ability} />
            </Route>
            <Route path="*" component={NotFound} />
         </Switch>
      </div>
   );
};

export default AdminRoutes;
