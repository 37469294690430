import React, { useEffect, useState } from "react";
import { Drawer, Layout } from "antd";

import SidebarContent from "./SidebarContent";

import {
   NAV_STYLE_DRAWER,
   NAV_STYLE_FIXED,
   NAV_STYLE_MINI_SIDEBAR,
   NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
   NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
   TAB_SIZE,
   THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";

import { layoutSettingsVar, globalSettingsVar } from "../../apollo/local/cache";
import { useReactiveVar } from "@apollo/client";

const { Sider } = Layout;

const Sidebar = ({ user, workspaces }) => {
   const { themeType, navStyle } = useReactiveVar(layoutSettingsVar);
   const { navCollapsed, width } = useReactiveVar(globalSettingsVar);

   //
   const sidCol = localStorage.getItem("SIDER_COLLAPSED");
   const siderInitialCollapsed =
      width < TAB_SIZE ||
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      sidCol === "true";

   const [sidebarCollapsed, setSidebarCollapsed] = useState(siderInitialCollapsed);
   //

   const onToggleCollapsedNav = () => {
      globalSettingsVar({ ...globalSettingsVar(), navCollapsed: false });
   };

   useEffect(() => {
      if (width < TAB_SIZE || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
         setSidebarCollapsed(true);
      }
   }, [navStyle, width]);

   useEffect(() => {
      window.addEventListener("resize", () => {
         globalSettingsVar({ ...globalSettingsVar(), width: window.innerWidth });
      });
   }, []);

   let drawerStyle = "gx-collapsed-sidebar";

   if (navStyle === NAV_STYLE_FIXED) {
      drawerStyle = "";
   } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
   } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      drawerStyle = "gx-custom-sidebar";
   } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar";
   } else if (navStyle === NAV_STYLE_DRAWER) {
      drawerStyle = "gx-collapsed-sidebar";
   }
   if (
      (navStyle === NAV_STYLE_FIXED ||
         navStyle === NAV_STYLE_MINI_SIDEBAR ||
         navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) &&
      width < TAB_SIZE
   ) {
      drawerStyle = "gx-collapsed-sidebar";
   }

   const setSidebarCollapsedHandler = (collapsed) => {
      localStorage.setItem("SIDER_COLLAPSED", collapsed);
      setSidebarCollapsed(collapsed);
   };

   return (
      <Sider
         className={`gx-app-sidebar ${drawerStyle} ${
            themeType !== THEME_TYPE_LITE ? "gx-layout-sider-dark" : null
         }`}
         trigger={null}
         collapsed={sidebarCollapsed && width >= TAB_SIZE}
         theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
         collapsible
      >
         {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
            <Drawer
               className={`gx-drawer-sidebar ${
                  themeType !== THEME_TYPE_LITE ? "gx-drawer-sidebar-dark" : null
               }`}
               placement="left"
               closable={false}
               onClose={onToggleCollapsedNav}
               visible={navCollapsed}
            >
               <SidebarContent
                  user={user}
                  workspaces={workspaces}
                  sidebarCollapsed={sidebarCollapsed}
                  setSidebarCollapsed={() => {
                     setSidebarCollapsedHandler(!sidebarCollapsed);
                     globalSettingsVar({
                        ...globalSettingsVar(),
                        sideBarCollapsed: !sidebarCollapsed,
                     });
                  }}
               />
            </Drawer>
         ) : (
            <SidebarContent
               user={user}
               workspaces={workspaces}
               sidebarCollapsed={sidebarCollapsed}
               setSidebarCollapsed={() => {
                  setSidebarCollapsedHandler(!sidebarCollapsed);
                  globalSettingsVar({
                     ...globalSettingsVar(),
                     sideBarCollapsed: !sidebarCollapsed,
                  });
               }}
            />
         )}
      </Sider>
   );
};
export default Sidebar;
