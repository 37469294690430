import { CloudDownloadOutlined } from "@ant-design/icons";
import React from "react";
import { injectIntl } from "react-intl";
import BadResponse from "../../containers/BadResponse";

class ErrorBoundary extends React.Component {
   constructor(props) {
      super(props);
      this.state = { hasError: false };
   }

   static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.      
      return { hasError: true, error: error?.name };
   }

   componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, errorInfo);
   }

   render() {

      if (this.state.hasError) {
         // You can render any custom fallback UI

         if (this.state.error === "ChunkLoadError") {
            return (
               <BadResponse
                  icon={<CloudDownloadOutlined />}
                  status="warning"
                  title={this.props.intl.formatMessage({ id: "500.network_error_or_update" })}
                  subtitle={this.props.intl.formatMessage({
                     id: "500.network_error_or_update_text",
                  })}
               />
            );

         } else {

            return (
               <BadResponse
                  title={this.props.intl.formatMessage({ id: "500.ooops" })}
                  subtitle={this.props.intl.formatMessage({
                     id: "500.something_wrong",
                  })}
               />
            );
         }
      }

      return this.props.children;
   }
}

export default injectIntl(ErrorBoundary);
