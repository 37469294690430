import { CalendarOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Col, List, Row, Skeleton, Spin, Tag } from "antd";
import React from "react";
import IntlMessages from "../../../util/IntlMessages";
import { Link } from "react-router-dom";
import { getAdminPath } from "../../../util/router";
import { useIntl } from "react-intl";
import { EventType } from "../../../constants/Enums";
import { getEventTypeColor } from "../functions";
import { formatDate } from "../../../util/date";

const getEventAction = (event) => {
   switch (event.type) {
      case EventType.AVVISO_ACCERTAMENTO:
         return (
            <Link to={getAdminPath(`workarea/dossier/update/${event.model_id}`)}>
               <CaretRightOutlined />
            </Link>
         );

      case EventType.SCADENZA_BOLLETTA:
         return (
            <Link to={getAdminPath(`workarea/tari/view/${event.model_id}`)}>
               <CaretRightOutlined />
            </Link>
         );

      default:
         return null;
   }
};

const getEventTypeBadge = (type) => {
   return (
      <Tag
         color={getEventTypeColor(type)}
         className="gx-p-0 gx-pl-1 gx-pr-1 gx-m-0 gx-mr-1 gx-mb-0"
         style={{ fontSize: 8 }}
      >
         <IntlMessages id={`events.type_${type}`} />
      </Tag>
   );
};

const EventsList = ({ events, loading, days }) => {
   const intl = useIntl();
   if (loading)
      return (
         <div className="gx-p-2 gx-text-center">
            <Spin className="gx-p-0 gx-m-0" />
         </div>
      );

   if (!events || events.length === 0) {
      return (
         <div className="gx-p-2 gx-text-center">
            <small>
               {intl.formatMessage(
                  { id: "events.widget_next_x_days_empty" },
                  { days: 30 }
               )}
            </small>
         </div>
      );
   }

   return (
      <List
         className="demo-loadmore-list"
         itemLayout="horizontal"
         dataSource={events}
         header={
            <Row>
               <Col xs={20}>
                  <span className="gx-font-weight-semi-bold">
                     {intl.formatMessage(
                        { id: "events.widget_next_x_days_p1" },
                        { days }
                     )}
                  </span>
               </Col>
               <Col xs={4} className="gx-text-right">
                  <Link to={getAdminPath("dashboard")}>
                     <CalendarOutlined
                        className="gx-text-underline gx-text-info"
                        title={intl.formatMessage({ id: "common.deadlines" })}
                     />
                  </Link>
               </Col>
            </Row>
         }
         style={{
            width: 300,
            maxHeight: 400,
            overflowY: "auto",
            overflowX: "hidden",
         }}
         rowKey="id"
         renderItem={(event) => {
            const dateRef = event.reminder_at ? event.reminder_at : event.date;
            return (
               <List.Item actions={[getEventAction(event)]}>
                  <Skeleton title={false} loading={false} active>
                     <List.Item.Meta
                        title={`${formatDate(dateRef)} ${event.title}`}
                        description={
                           <>
                              {getEventTypeBadge(event.type)}
                              <small>{event.description}</small>
                           </>
                        }
                     />
                  </Skeleton>
               </List.Item>
            );
         }}
      />
   );
};

export default EventsList;
